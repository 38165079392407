import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useMediaQuery, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import axios from "axios";
import CustomDriverSelect from "./CustomDriverSelect";
import CircularProgress from "@mui/material/CircularProgress";

///// Accordion IMports/////
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgb(66 243 4 / 33%);",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

///// Accordion IMports/////

const DynamicItemBox = ({ companyID, setCompanies, drivers }) => {
  ///// Accordion CONSTS/////
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  ///// Accordion CONSTS/////
  // const [drivers, setDrivers] = useState([]);

  // useEffect(() => {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: "/api/getDrivers",
  //     headers: {},
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       // console.log(response.data);
  //       setDrivers(response.data.resultsGetDrivers);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // Function to initialize the items state from local storage or use a default value
  const initializeItems = () => {
    const storedData = JSON.parse(localStorage.getItem("companies")) || [];
    const companyData = storedData.find((company) => company.id === companyID);
    return companyData
      ? companyData.myitems
      : [
          {
            id: 0,
            invoiceID: "",
            itemPrice: "",
            itemPriceLBP: 0,
            paid: "1",
            exchange: "no",
            address: "",
            receiverName: "",
            receiverTel: "",
            receiverAddress: "",
            shipper: "0",
            shippmentStatus: "pickup",
            shippingCost: "0",
            totalPrice: "0",
            shipperProfit: "0",
            labaytakProfit: "0",
          },
        ];
  };

  // Item Object
  const [items, setItems] = useState(initializeItems);
  // console.log(items);

  const addItem = () => {
    const newItemsId = items.length;
    const newItems = [
      ...items,
      {
        id: newItemsId,
        invoiceID: "",
        itemPrice: "",
        itemPriceLBP: 0,
        paid: "1",
        exchange: "no",
        address: "",
        receiverName: "",
        receiverTel: "",
        receiverAddress: "",
        shipper: "0",
        shippmentStatus: "pickup",
        shippingCost: "0",
        totalPrice: "0",
        shipperProfit: "0",
        labaytakProfit: "0",
      },
    ];
    setItems(newItems);
  };

  const removeItem = (id) => {
    // const updatedItems = items.filter((item) => item.id !== id);
    // setItems(updatedItems);

    const updatedItems = items.filter((item) => item.id !== id);
    // Update the IDs of the remaining items
    const updatedItemsWithNewIds = updatedItems.map((item, index) => ({
      ...item,
      id: index,
    }));
    setItems(updatedItemsWithNewIds);
  };
  // InvoiceID
  const handleInvoiceIDChange = (id, newInvoiceID) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, invoiceID: newInvoiceID } : item
    );
    setItems(updatedItems);
  };
  // ItemPrice
  const handleItemPriceChange = (id, newItemPrice) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, itemPrice: newItemPrice } : item
    );
    setItems(updatedItems);
  };
  //handlePriceLBPChange
  const handlePriceLBPChange = (id, newItemPrice) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, itemPriceLBP: newItemPrice } : item
    );
    setItems(updatedItems);
  };
  // Paid Status
  const handlePaidChange = (id, newPaid) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, paid: newPaid } : item
    );
    setItems(updatedItems);
  };
  // Exchange
  const handleExchange = (id, newExchange) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, exchange: newExchange } : item
    );
    setItems(updatedItems);
  };
  // Address
  const handleAddress = (id, newAddress) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, address: newAddress } : item
    );
    setItems(updatedItems);
  };
  // Reciever Name
  const handleReceiverNameChange = (id, newName) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, receiverName: newName } : item
    );
    setItems(updatedItems);
  };
  // Reciever Name
  const handleReceiverTelChange = (id, newTel) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, receiverTel: newTel } : item
    );
    setItems(updatedItems);
  };
  // Reciever Address
  const handleReceiverAddressChange = (id, newAdd) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, receiverAddress: newAdd } : item
    );
    setItems(updatedItems);
  };
  // Shipper Name
  const handleShipperChange = (id, newShipper) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, shipper: newShipper } : item
    );
    setItems(updatedItems);
  };
  // Shipper Profit
  const handleShippmentStatusChange = (id, newStatus) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, shippmentStatus: newStatus } : item
    );
    setItems(updatedItems);
  };
  // Shipping Cost
  const handleShippingCostChange = (id, newCost) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, shippingCost: newCost } : item
    );
    setItems(updatedItems);
  };
  // Shipper Profit
  // const handleShipperProfitChange = (id, newShipperProfit) => {
  //   const updatedItems = items.map((item) =>
  //     item.id === id ? { ...item, shipperProfit: newShipperProfit } : item
  //   );
  //   setItems(updatedItems);
  // };

  const handleShipperProfitChange = (id, newShipperProfit) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        const shippingCost = parseFloat(item.shippingCost) || 0;
        const labaytakProfit = shippingCost - newShipperProfit;
        return { ...item, shipperProfit: newShipperProfit, labaytakProfit };
      } else {
        return item;
      }
    });
    setItems(updatedItems);
  };

  // Calculate total price based on itemPrice and shippingCost
  const calculateTotalPrice = (item) => {
    const itemPrice = parseFloat(item.itemPrice) || 0;
    const shippingCost = parseFloat(item.shippingCost) || 0;
    // console.log(itemPrice)
    // console.log(shippingCost)
    return itemPrice + shippingCost;
  };

  // Calculate total price based on itemPrice and shippingCost
  const calculateShipperProfit = (item) => {
    const shippingCost = parseFloat(item.shippingCost) || 0;
    return shippingCost / 2;
  };

  // Calculate total price based on itemPrice and shippingCost
  const calculateLabaytakProfit = (item) => {
    const shippingCost = parseFloat(item.shippingCost) || 0;
    // const shipperProfit = parseFloat(item.shipperProfit) || 0;
    // return shippingCost - shipperProfit;
    return shippingCost / 2;
  };
  // Update the totalPrice field whenever itemPrice or shippingCost changes
  const handleItemChange = (index, field, value) => {
    const updatedItems = items.map((item, i) =>
      i === index
        ? {
            ...item,
            [field]: value,
            totalPrice: calculateTotalPrice({
              ...item,
              [field]: value,
            }),
            shipperProfit: calculateShipperProfit({
              ...item,
              [field]: value,
            }),
            labaytakProfit: calculateLabaytakProfit({
              ...item,
              [field]: value,
            }),
          }
        : item
    );
    setItems(updatedItems);
  };

  // Function to update the local storage data for a specific company
  const updateLocalStorage = (companyID, newItems) => {
    const storedData = JSON.parse(localStorage.getItem("companies")) || [];
    const updatedData = storedData.map((company) =>
      company.id === companyID ? { ...company, myitems: newItems } : company
    );
    localStorage.setItem("companies", JSON.stringify(updatedData));
  };

  useEffect(() => {
    // Update the local storage when items change
    updateLocalStorage(companyID, items);

    setCompanies((prevCompanies) =>
      prevCompanies.map((c) =>
        c.id === companyID ? { ...c, myitems: items } : c
      )
    );
    // localStorage.setItem("companies", JSON.stringify(prevCompanies));
  }, [items]);

  // console.log(items)
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const deleteItemButton = {
    color: "#fff",
    backgroundColor: "#276329",
    borderColor: "#276329",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#268968",
      borderColor: "#268968",
      color: "#fff",
      boxShadow: "none",
    },
  };

  const itemLine = {
    display: "flex",
    flexDirection: "row",
    flexWrap: isBigScreen ? "nowrap" : "wrap",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px",
    marginTop: "10px",
  };

  const addItemAcc = {
    color: "#fff",
    width: "100%",
    backgroundColor: "#ea6e27",
    borderColor: "#ea6e27",
    "&:hover": {
      backgroundColor: "#276329",
      borderColor: "#276329",
      color: "#fff",
      boxShadow: "none",
    },
  };

  const inputLine = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  };

  const checkInvoice = (index, invoice) => {
    let elementID = "invoiceid" + index;
    let loader = "load" + index;
    let small = "small" + index;
    document.getElementById(loader).style.display = "inline-flex";
    let data = new FormData();
    data.append("invoiceID", invoice);

    let config = {
      method: "post",
      url: "/api/getInvoiceById",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success) {
          document.getElementById(elementID).focus();
          document.getElementById(loader).style.display = "none";
          document.getElementById(small).style.display = "inline-flex";
        }
      })
      .catch((error) => {
        // console.log("invoice available");
        document.getElementById(loader).style.display = "none";
        document.getElementById(small).style.display = "none";
      });
  };

  const numberInputs = document.querySelectorAll('input[type="number"]');

  numberInputs.forEach((input) => {
    input.addEventListener("wheel", (e) => {
      // Prevent the default behavior of mouse wheel scrolling
      e.preventDefault();
    });
  });

  return (
    <>
      <Divider />

      {items.map((item, index) => (
        <div key={item.id}>
          <Box
            key={item.id}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Accordion
              sx={{ m: 1, width: "92%" }}
              expanded={expanded === `panel${item.id}`}
              onChange={handleChange(`panel${item.id}`)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Item {item.id + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={itemLine}>
                  <Box sx={inputLine}>
                    <label
                      style={{
                        width: "25%",
                        textAlign: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      InvoiceID
                      <CircularProgress
                        id={`load${item.id}`}
                        color="success"
                        sx={{ scale: "0.5", display: "none" }}
                      />
                      <small
                        id={`small${item.id}`}
                        style={{ color: "#ff0000", display: "none" }}
                      >
                        exist!
                      </small>
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      {/* <input
                        id={`invoiceid${item.id}`}
                        label={`InvoiceID`}
                        type="text"
                        value={item.invoiceID}
                        onChange={(e) =>
                          handleInvoiceIDChange(item.id, e.target.value)
                        }
                        onBlur={(e) => checkInvoice(item.id, e.target.value)}
                      /> */}
                      <TextField
                        id={`invoiceid${item.id}`}
                        label={`InvoiceID`}
                        type="text"
                        value={item.invoiceID}
                        onChange={(e) =>
                          handleInvoiceIDChange(item.id, e.target.value)
                        }
                        onBlur={(e) => checkInvoice(item.id, e.target.value)}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Price USD
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextField
                        id={`itemprice${item.id}`}
                        label={`Item${index + 1}Price USD`}
                        type="number"
                        value={item.itemPrice}
                        onChange={(e) => {
                          handleItemPriceChange(
                            item.id,
                            parseFloat(e.target.value)
                          );
                          handleItemChange(index, "itemPrice", e.target.value);
                        }}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Price LBP
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextField
                        id={`itempricelbp${item.id}`}
                        label={`Item${index + 1}Price LBP`}
                        type="number"
                        value={item.itemPriceLBP}
                        onChange={(e) => {
                          handlePriceLBPChange(
                            item.id,
                            parseFloat(e.target.value)
                          );
                          // handleItemChange(index, "itemPrice", e.target.value);
                        }}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Paid to Page
                    </label>

                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <InputLabel id="select-paid-label">Paid</InputLabel>
                      <Select
                        labelId="select-paid"
                        id="select-paid"
                        value={item.paid}
                        label="Paid"
                        onChange={(e) =>
                          handlePaidChange(
                            item.id,
                            parseFloat(e.target.value) || 1
                          )
                        }
                      >
                        <MenuItem value={1}>yes</MenuItem>
                        <MenuItem value={2}>no</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box sx={itemLine}>
                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Exchange
                    </label>

                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <InputLabel id="select-exchange">Exchange</InputLabel>
                      <Select
                        labelId="exchange"
                        id="select-exchange"
                        value={item.exchange}
                        label="exchange"
                        onChange={(e) =>
                          handleExchange(item.id, e.target.value)
                        }
                      >
                        <MenuItem value={"yes"}>yes</MenuItem>
                        <MenuItem value={"no"}>no</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Receiver Name
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextField
                        id={`receiver${item.id}`}
                        label={`Receiver Name`}
                        type="text"
                        value={item.receiverName}
                        onChange={(e) =>
                          handleReceiverNameChange(item.id, e.target.value)
                        }
                      />
                    </FormControl>
                  </Box>

                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Receiver Tel
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextField
                        id={`receiverTel${item.id}`}
                        label={`Receiver Tel`}
                        type="text"
                        value={item.receiverTel}
                        onChange={(e) =>
                          handleReceiverTelChange(item.id, e.target.value)
                        }
                      />
                    </FormControl>
                  </Box>

                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Address
                    </label>

                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <InputLabel id="select-address">Address</InputLabel>
                      <Select
                        labelId="address"
                        id="select-address"
                        value={item.address}
                        label="address"
                        onChange={(e) => handleAddress(item.id, e.target.value)}
                      >
                        <MenuItem value={""}>Select Address</MenuItem>
                        <MenuItem value={"bechmon - baabda - aramoun"}>
                          bechmon - baabda - aramoun
                        </MenuItem>
                        <MenuItem value={"beirut"}>beirut</MenuItem>
                        <MenuItem value={"bekaa"}>bekaa</MenuItem>
                        <MenuItem value={"bnt jbeil"}>bnt jbeil</MenuItem>
                        <MenuItem value={"chouf "}>chouf </MenuItem>
                        <MenuItem value={"eklim"}>eklim</MenuItem>
                        <MenuItem value={"jbeil"}>jbeil</MenuItem>
                        <MenuItem value={"maten - aley"}>maten - aley</MenuItem>
                        <MenuItem value={"maten sahli"}>maten sahli</MenuItem>
                        <MenuItem value={"nabatieh - Marjaayon"}>
                          nabatieh - Marjaayon
                        </MenuItem>
                        <MenuItem value={"saida"}>saida</MenuItem>
                        <MenuItem value={"sour"}>sour</MenuItem>
                        <MenuItem value={"tripoli"}>tripoli</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box sx={itemLine}>
                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Receiver Address
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextareaAutosize
                        minRows={3}
                        value={item.receiverAddress}
                        onChange={(e) =>
                          handleReceiverAddressChange(item.id, e.target.value)
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: isBigScreen ? "40%" : "100%",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Shipper
                    </label>
                    <FormControl sx={{ m: 1, width: "75%" }} size="medium">
                      <InputLabel id="select-shipper-label">Shipper</InputLabel>
                      {drivers ? (
                        <CustomDriverSelect
                          drivers={drivers}
                          value={item.shipper}
                          onChange={(e) =>
                            handleShipperChange(item.id, e.target.value)
                          }
                        />
                      ) : (
                        <p>Loading drivers...</p>
                      )}
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: isBigScreen ? "40%" : "100%",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Shipment Status
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextField
                        id={`shipStatus${item.id}`}
                        label={`Shipment Status`}
                        type="text"
                        value={item.shippmentStatus}
                        onChange={(e) =>
                          handleShippmentStatusChange(item.id, e.target.value)
                        }
                        disabled
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box sx={itemLine}>
                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Shipping Cost
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextField
                        id={`shipCost${item.id}`}
                        label={`Shipping Cost`}
                        type="number"
                        value={item.shippingCost}
                        onChange={(e) => {
                          handleShippingCostChange(
                            item.id,
                            parseFloat(e.target.value)
                          );
                          handleItemChange(
                            index,
                            "shippingCost",
                            e.target.value
                          );
                        }}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Total Price
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextField
                        id={`tPrice${item.id}`}
                        label={`Total Price`}
                        type="number"
                        value={item.totalPrice}
                        disabled
                      />
                    </FormControl>
                  </Box>

                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Shipper Profit
                    </label>
                    <FormControl
                      required
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                    >
                      <TextField
                        id={`shipperProfit${item.id}`}
                        name={`shipperProfit${item.id}`}
                        label={`Shipper Profit`}
                        type="number"
                        value={item.shipperProfit}
                        disabled={false}
                        onChange={(e) => {
                          handleShipperProfitChange(
                            item.id,
                            parseFloat(e.target.value)
                          );
                          // handleItemChange(
                          //   index,
                          //   "shipperProfit",
                          //   e.target.value
                          // );
                        }}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={inputLine}>
                    <label style={{ width: "25%", textAlign: "center" }}>
                      Labaytak Profit
                    </label>
                    <FormControl
                      sx={{ m: 1, width: "75%" }}
                      size="medium"
                      id={`lProfitfrm${item.id}`}
                    >
                      <TextField
                        id={`lProfit${item.id}`}
                        label={`Labaytak Profit`}
                        type="number"
                        value={item.labaytakProfit}
                        disabled
                      />
                    </FormControl>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Button sx={deleteItemButton} onClick={() => removeItem(item.id)}>
              {isBigScreen ? "" : "Delete Item"}
              <DeleteForeverOutlinedIcon />
            </Button>
          </Box>

          <Divider />
        </div>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Button sx={addItemAcc} onClick={addItem}>
          Add Item <AddCircleOutlineOutlinedIcon />
        </Button>
      </Box>
    </>
  );
};

export default DynamicItemBox;
