import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PaidIcon from "@mui/icons-material/Paid";
import CircularProgress from "@mui/material/CircularProgress";

import { AgChartsReact } from "ag-charts-react";

const formatter = ({ value }) => (value == null ? "" : value.toFixed(0));

const Numbers = ({ gCapital }) => {
  // console.log(gCapital)
  const now = new Date();
  const today =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  now.setDate(now.getDate() - 30);
  const month =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  const [fromDate, setFromDate] = useState(month);
  const [toDate, setToDate] = useState(today);

  const lineBox = {
    display: "flex",
    // flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0",
  };

  const [options, setOptions] = useState({
    title: {
      text: "Top 5 pages by revenue",
    },
    subtitle: {
      text: "in U.S. dollars",
    },
    data: [],
    series: [
      {
        type: "column",
        xKey: "page",
        yKey: "sales",
        label: { formatter },
      },
    ],
  });

  const [revenue, setRevenue] = useState([]);
  const [totalProfit, setTotalProfit] = useState("0");
  const [totalCost, setTotalCost] = useState("0");
  const [netProfit, setNetProfit] = useState("0");
  const [inMarket, setInMarket] = useState("0");
  const [cash, setCash] = useState(0);
  // console.log(capital);

  useEffect(() => {
    document.getElementById("loaderNN").style.visibility = "visible";
    let data = new FormData();
    data.append("fromDate", fromDate);
    data.append("toDate", toDate);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/getPagesRevenue",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const transformedData = response.data.resultsPagesRevenue.map(
          (row) => ({
            page: row.companyName,
            sales: row.total_profit,
          })
        );
        // setTotalProfit(response.data.totalProfit);
        setTotalCost(response.data.totalCost);
        setRevenue(transformedData);
      })
      .catch((error) => {
        setRevenue([]);
        document.getElementById("loaderNN").style.visibility = "hidden";
      });

    //////////////
    let data2 = new FormData();
    data2.append("fromDate", fromDate);
    data2.append("toDate", toDate);
    // Fetch data
    let config2 = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/getInMarket",
      headers: {
        "Content-Type": "application/json",
      },
      data: data2,
    };

    axios
      .request(config2)
      .then((response2) => {
        setInMarket(response2.data.resultsgetInMarket[0].total_market);
        setTotalProfit(response2.data.resultsgetProfit[0].total_profit);
        document.getElementById("loaderNN").style.visibility = "hidden";
      })
      .catch((error) => {
        document.getElementById("loaderNN").style.visibility = "hidden";
      });
  }, [fromDate, toDate]);

  useEffect(() => {
    // console.log(gCapital - inMarket);
    setCash(gCapital - inMarket);
  }, [gCapital, inMarket]);

  useEffect(() => {
    setNetProfit(totalProfit - totalCost);
  }, [totalProfit, totalCost]);

  useEffect(() => {
    const newOptions = { ...options };
    newOptions.data = revenue;
    setOptions(newOptions);
  }, [revenue]);

  return (
    <>
      <Box sx={lineBox}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            defaultValue={dayjs(month)}
            onChange={(newDate) =>
              setFromDate(dayjs(newDate).format("YYYY-MM-DD"))
            }
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            defaultValue={dayjs(today)}
            onChange={(newDate) =>
              setToDate(dayjs(newDate).format("YYYY-MM-DD"))
            }
          />
        </LocalizationProvider>
      </Box>
      <div
        style={{
          // display: "inline-flex",
          // justifyContent: "center",
          // marginTop: "10px",
          // gap: "20px",
          textAlign: "center",
        }}
      >
        <CircularProgress
          id="loaderNN"
          color="success"
          sx={{ visibility: "visible" }}
        />
      </div>
      <Box sx={lineBox}>
        <AgChartsReact options={options} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // width: "100%",
            alignItems: "center",
            justifyContent: "center",
            // margin: "10px 0",
          }}
        >
          <Box sx={lineBox}>
            <Card
              // className="grid-item item1"
              sx={{
                display: "flex",
                backgroundColor: "#ea6e27",
                backgroundImage:
                  "linear-gradient(to right, #f9741087, #db75291a)",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                color: "#fff",
                padding: "0.5rem",
                fontSize: "14px",
                position: "relative",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{ fontFamily: "Oswald, sans-serif" }}
                  >
                    In Market
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.white"
                    component="div"
                  >
                    {inMarket}
                  </Typography>
                </CardContent>
              </Box>

              <CardMedia
                component={AttachMoneyIcon}
                sx={{
                  width: 151,
                  color: "rgba(255, 255, 255, 0.4)",
                  fontSize: "5.5rem",
                }}
              />
            </Card>

            <Card
              // className="grid-item item2"
              sx={{
                display: "flex",
                backgroundColor: "#ea6e27",
                backgroundImage:
                  "linear-gradient(to right, #f9741087, #db75291a)",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                color: "#fff",
                padding: "0.5rem",
                fontSize: "14px",
                position: "relative",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{ fontFamily: "Oswald, sans-serif" }}
                  >
                    In Cash
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.white"
                    component="div"
                  >
                    {cash !== 0 ? `${cash}` : "0"}
                  </Typography>
                </CardContent>
              </Box>

              <CardMedia
                component={AttachMoneyIcon}
                sx={{
                  width: 151,
                  color: "rgba(255, 255, 255, 0.4)",
                  fontSize: "5.5rem",
                }}
              />
            </Card>
          </Box>
          <Box sx={lineBox}>
            <Card
              sx={{
                display: "flex",
                backgroundColor: "#ea6e27",
                backgroundImage:
                  "linear-gradient(to right, #f9741087, #db75291a)",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                color: "#fff",
                padding: "0.5rem",
                fontSize: "14px",
                position: "relative",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{ fontFamily: "Oswald, sans-serif" }}
                  >
                    Total Profit
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.white"
                    component="div"
                  >
                    {totalProfit}
                  </Typography>
                </CardContent>
              </Box>

              <CardMedia
                component={AttachMoneyIcon}
                sx={{
                  width: 151,
                  color: "rgba(255, 255, 255, 0.4)",
                  fontSize: "5.5rem",
                }}
              />
            </Card>

            <Card
              // className="grid-item"
              sx={{
                display: "flex",
                backgroundColor: "#268968",
                backgroundImage:
                  "linear-gradient(to right, #26896838, #268968ba)",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                color: "#fff",
                padding: "0.5rem",
                fontSize: "14px",
                position: "relative",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{ fontFamily: "Oswald, sans-serif" }}
                  >
                    Payments
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.white"
                    component="div"
                  >
                    {totalCost}
                  </Typography>
                </CardContent>
              </Box>

              <CardMedia
                component={MoneyOffIcon}
                sx={{
                  width: 151,
                  color: "rgba(255, 255, 255, 0.4)",
                  fontSize: "5.5rem",
                }}
              />
            </Card>

            <Card
              // className="grid-item"
              sx={{
                display: "flex",
                backgroundColor: "#ea6e27",
                backgroundImage:
                  "linear-gradient(to right, #f9741087, #db75291a)",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                color: "#fff",
                padding: "0.5rem",
                fontSize: "14px",
                position: "relative",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{ fontFamily: "Oswald, sans-serif" }}
                  >
                    NET Profit
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.white"
                    component="div"
                  >
                    {netProfit}
                  </Typography>
                </CardContent>
              </Box>

              <CardMedia
                component={PaidIcon}
                sx={{
                  width: 151,
                  color: "rgba(255, 255, 255, 0.4)",
                  fontSize: "5.5rem",
                }}
              />
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Numbers;
