import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Cookies from "js-cookie";

const NumberCapital = ({ setGCapital }) => {
  const [capital, setCapital] = useState();
  const [oldCapital, setOldCapital] = useState();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getCapital",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.resultsGetCapital)
        setCapital(response.data.resultsGetCapital[0].capital);
        setOldCapital(response.data.resultsGetCapital[0].capital);
        setGCapital(response.data.resultsGetCapital[0].capital)
      })
      .catch((error) => {
        setCapital("");
      });
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);

    const user = Cookies.get("username");

    let data = new FormData();
    data.append("newCapital", capital);
    data.append("oldCapital", oldCapital);
    data.append("user", user);

    let config = {
      method: "patch",
      url: "/api/updateCapital",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success) {
          alert(response.data.message);
          window.location.reload();
        } else {
          alert("failed to update Capital");
        }
      })
      .catch((error) => {
        alert("failed to update Capital");
      });
  };

  const numberInputs = document.querySelectorAll('input[type="number"]');

  numberInputs.forEach((input) => {
    input.addEventListener("wheel", (e) => {
      // Prevent the default behavior of mouse wheel scrolling
      e.preventDefault();
    });
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <h2>Capital:</h2>
      {isEditing ? (
        <TextField
          id="capital"
          //   label="Capital"
          type="number"
          variant="outlined"
          value={capital}
          onChange={(e) => setCapital(e.target.value)}
        />
      ) : (
        <h2>{capital} $</h2>
      )}
      {isEditing ? (
        <IconButton aria-label="Save" onClick={handleSaveClick}>
          <SaveIcon />
        </IconButton>
      ) : (
        <IconButton aria-label="Edit" onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default NumberCapital;
