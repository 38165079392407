import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useMediaQuery, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

let rowImmutableStore;

const Users = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const gridRef = useRef(); // Optional - for accessing Grid's API

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { field: "FirstName" },
    { field: "LastName" },
    { field: "UserName" },
    { field: "Password", headerName: "Password ✏️", editable: true },
    { field: "Role" },
    { field: "Status", headerName: "Status ✏️", editable: true },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));
  // const enableRangeSelection = true;
  // const undoRedoCellEditing = true;
  // const enableCellChangeFlash = true;
  // const enableFillHandle = true;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    document.getElementById("loaderU").style.visibility = "visible";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getAllUsers",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        const transformedData = response.data.resultsGetAllUsers.map(
          (user) => ({
            id: user.userID,
            FirstName: user.firstname,
            LastName: user.lastname,
            UserName: user.username,
            Password: user.password,
            Role: user.role,
            Status: user.status,
          })
        );
        transformedData.shift();
        // console.log(transformedData)
        rowImmutableStore = transformedData;
        setUsers(rowImmutableStore);
        document.getElementById("loaderU").style.visibility = "hidden";
      })
      .catch((error) => {
        setUsers([]);
        document.getElementById("loaderU").style.visibility = "hidden";
      });
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);
  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);
  const [pendingChanges, setPendingChanges] = useState([]);
  // console.log("pendingChanges", pendingChanges.length);
  const onCellEditRequest = useCallback(
    (event) => {
      const data = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const oldItem = rowImmutableStore.find((row) => row.id === data.id);
      if (!oldItem || !field) {
        return;
      }
      // console.log(oldItem.UserName);
      const user = Cookies.get("username");
      const newItem = { ...oldItem };
      newItem[field] = newValue;
      // console.log("onCellEditRequest, updating " + field + " to " + newValue);
      if (field === "Password") {
        const change = {
          field: "Password",
          username: oldItem.UserName,
          newValue,
          oldValue: oldItem.Password,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "Status") {
        const change = {
          field: "Status",
          username: oldItem.UserName,
          newValue,
          oldValue: oldItem.Status,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      }

      rowImmutableStore = rowImmutableStore.map((oldItem) =>
        oldItem.id === newItem.id ? newItem : oldItem
      );
      setUsers(rowImmutableStore);
    },
    [rowImmutableStore]
  );

  const saveChanges = () => {
    document.getElementById("loaderS").style.visibility = "visible";
    const successfulChanges = [];
    const failedChanges = [];

    pendingChanges.forEach((change) => {
      const { field, username, user, newValue, oldValue } = change;

      let apiUrl;
      let requestData = new FormData();

      if (field === "Password") {
        apiUrl = "/api/updatePassword";
        requestData.append("username", username);
        requestData.append("password", newValue);
        requestData.append("oldPassword", oldValue);
        requestData.append("user", user);
      } else if (field === "Status") {
        apiUrl = "/api/updateStatus";
        requestData.append("username", username);
        requestData.append("status", newValue);
        requestData.append("oldstatus", oldValue);
        requestData.append("user", user);
      }

      axios
        .request({
          method: "patch",
          url: apiUrl,
          headers: {
            "Content-Type": "application/json",
          },
          data: requestData,
        })
        .then((response) => {
          if (response.data.success) {
            // console.log(`${field} for ${username} has been updated!`);
            successfulChanges.push(change);
          } else {
            // console.log(`Failed to update ${field} for ${username}`);
            failedChanges.push(change);
          }

          const totalChanges = successfulChanges.length + failedChanges.length;
          if (totalChanges === pendingChanges.length) {
            if (successfulChanges.length === pendingChanges.length) {
              alert(`All changes were updated!`);
              setPendingChanges([]);
              document.getElementById("loaderS").style.visibility = "hidden";
              window.location.reload();
            } else {
              alert(`Failed to edit ${failedChanges.length} records`);
              setPendingChanges([]);
              document.getElementById("loaderS").style.visibility = "hidden";
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          failedChanges.push(change);
          document.getElementById("loaderS").style.visibility = "hidden";
        });
    });
  };

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const lineBox = {
    display: "flex",
    // flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    margin: "5px 0",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    margin: !isBigScreen && "10px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [uname, setuname] = useState("");
  const [password, setpassword] = useState("");
  const [role, setrole] = useState("driver");

  const addUser = () => {
    document.getElementById("loaderM").style.visibility = "visible";
    if (fname === "" || lname === "" || uname === "" || password === "") {
      return alert("Enter all values");
    } else {
      let data = new FormData();
      data.append("fname", fname);
      data.append("lname", lname);
      data.append("uname", uname);
      data.append("password", password);
      data.append("role", role);

      let config = {
        method: "post",
        url: "/api/createUser",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response.data)
          if (response.data.success) {
            alert("User Created Successfully");
            document.getElementById("loaderM").style.visibility = "hidden";
            window.location.reload();
          } else {
            alert(response.data.message);
            document.getElementById("loaderM").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to create user");
          document.getElementById("loaderM").style.visibility = "hidden";
        });
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows());
  };
  // console.log(selectedRows)
  const handleDeleteUser = () => {
    if (window.confirm("Are you sure you want to delete user!")) {
      document.getElementById("loaderDL").style.visibility = "visible";
      let user = Cookies.get("username");

      let data = new FormData();
      data.append("usersIDs", JSON.stringify(selectedRows));
      data.append("userr", user);

      let config = {
        method: "post",
        url: "/api/multiDeleteUsers",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            alert(response.data.message);
            document.getElementById("loaderDL").style.visibility = "hidden";
            window.location.reload();
          } else {
            alert("Failed to delete user");
            document.getElementById("loaderDL").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to delete user");
          document.getElementById("loaderDL").style.visibility = "hidden";
        });
    }
  };

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          marginTop: "10px",
          gap: "20px",
        }}
      >
        <h2>Users</h2>
        <CircularProgress
          id="loaderU"
          color="success"
          sx={{ visibility: "hidden" }}
        />
      </div>
      {/* Example using Grid's API */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>
              FirstName
            </label>
            <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
              <TextField
                id="FirstName"
                onChange={(event) => setfname(event.target.value)}
                label="FirstName"
                type="text"
                value={fname}
              />
            </FormControl>
          </Box>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>
              Last Name
            </label>
            <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
              <TextField
                id="lastname"
                onChange={(event) => setlname(event.target.value)}
                label="Last Amount"
                type="text"
                value={lname}
              />
            </FormControl>
          </Box>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>
              User Name
            </label>
            <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
              <TextField
                id="username"
                onChange={(event) => setuname(event.target.value)}
                label="User Name"
                type="text"
                value={uname}
              />
            </FormControl>
          </Box>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>
              Password
            </label>
            <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
              <TextField
                id="fundsAmount"
                onChange={(event) => setpassword(event.target.value)}
                label="Password"
                type="text"
                value={password}
              />
            </FormControl>
          </Box>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>Role</label>

            <FormControl sx={{ m: 1, width: "80%" }} size="medium">
              <InputLabel id="select-role">Role</InputLabel>
              <Select
                labelId="Role"
                id="select-role"
                value={role}
                label="Role"
                onChange={(e) => setrole(e.target.value)}
              >
                <MenuItem value="driver">Driver</MenuItem>
                <MenuItem value="data-entry">Data Entry</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                backgroundColor: "#126E82",
                "&:hover": {
                  backgroundColor: "#0A4C5A",
                },
              }}
              endIcon={<AddCircleOutlineIcon />}
              onClick={addUser}
            >
              Add
            </Button>
          </Box>
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <CircularProgress
              id="loaderM"
              color="success"
              sx={{ visibility: "hidden" }}
            />
          </div>
        </Box>
      </Modal>
      <Box sx={lineBox}>
        <button onClick={() => autoSizeAll(false)}>Auto-Size All</button>
        <Button onClick={handleOpen} sx={{ color: "#268968" }}>
          Add
        </Button>

        <button
          onClick={handleDeleteUser}
          disabled={selectedRows.length > 0 ? false : true}
        >
          Delete Users
        </button>
        <CircularProgress
          id="loaderDL"
          color="success"
          sx={{ visibility: "hidden" }}
        />

        <button
          onClick={saveChanges}
          disabled={pendingChanges.length > 0 ? false : true}
        >
          Save Changes
        </button>
        <CircularProgress
          id="loaderS"
          color="success"
          sx={{ visibility: "hidden" }}
        />
      </Box>
      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
      <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
        <AgGridReact
          ref={gridRef}
          rowData={users}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          rowSelection="multiple"
          onSelectionChanged={onSelectionChanged}
          onCellClicked={cellClickedListener}
          suppressRowClickSelection={true}
          readOnlyEdit={true}
          onCellEditRequest={onCellEditRequest}
        />
      </div>
    </>
  );
};

export default Users;
