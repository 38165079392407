import React, { useState, useEffect } from "react";
import "./App.css";
import LoginPage from "./components/LoginPage";
import AppDrawer from "./components/AppDrawer";

import AllOrders from "./components/AllOrders";
import CreateOrders from "./components/CreateOrders";
import Users from "./components/Users";
import Pages from "./components/Pages";

import Cookies from "js-cookie";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import DriverView from "./components/DriverView";
import EditHistory from "./components/EditHistory";
import DriversAccount from "./components/DriversAccount";
import PageAccount from "./components/PageAccount";
import Payments from "./components/Payments";
import Numbers from "./components/NumbersPage";
import ArchivedInvoices from "./components/ArchivedInvoices";
import InvoiceDetails from "./components/InvoiceDetails";

function App() {
  // const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(
    Cookies.get("loggedIn") === "logged" ? true : false
  );
  const [role, setRole] = useState(Cookies.get("role") ? Cookies.get("role") : "");

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            loggedIn === "logged" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="login"
          element={<LoginPage setLoggedIn={setLoggedIn} setRole={setRole} />}
        />
        <Route
          path="dashboard"
          element={
            <AppDrawer
              setLoggedIn={setLoggedIn}
              role={role}
              setRole={setRole}
            />
          }
        >
          {role === "admin" ? (
            <>
              {/* <Route path="" element={<Navigate to={path} />} /> */}
              <Route path="all-orders" element={<AllOrders />} />
              <Route path="create-orders" element={<CreateOrders />} />
              <Route path="users" element={<Users />} />
              <Route path="pages" element={<Pages />} />
              <Route path="edit-history" element={<EditHistory />} />
              <Route path="drivers-account" element={<DriversAccount />} />
              <Route path="page-account" element={<PageAccount />} />
              <Route path="invoice-details" element={<InvoiceDetails />} />
              <Route path="payments" element={<Payments />} />
              <Route path="numbers" element={<Numbers />} />
              <Route path="archive" element={<ArchivedInvoices />} />

              <Route path="*" element={<Navigate to="all-orders" />} />
            </>
          ) : role === "data-entry" ? (
            <>
              {/* <Route path="" element={<Navigate to={path} />} /> */}
              <Route path="all-orders" element={<AllOrders />} />
              <Route path="create-orders" element={<CreateOrders />} />
              <Route path="users" element={<Users />} />
              <Route path="pages" element={<Pages />} />
              <Route path="edit-history" element={<EditHistory />} />
              <Route path="drivers-account" element={<DriversAccount />} />
              <Route path="page-account" element={<PageAccount />} />
              <Route path="invoice-details" element={<InvoiceDetails />} />
              <Route path="payments" element={<Payments />} />
              <Route path="numbers" element={<Numbers />} />
              <Route path="archive" element={<ArchivedInvoices />} />
              
              <Route path="*" element={<Navigate to="all-orders" />} />
            </>
          ) : (
            role === "driver" && (
              <>
                {/* <Route path="" element={<Navigate to={path} />} /> */}
                <Route path="driver" element={<DriverView />} />
                <Route path="*" element={<Navigate to="driver" />} />
              </>
            )
          )}
        </Route>
        <Route path="*" element={<Navigate to={loggedIn ? "dashboard/all-orders" : "login"} />} />
      </Routes>
    </>
  );
}

export default App;
