import React, { useState } from "react";
import Numbers from "./subcomponents/Numbers";
import NumberCompare from "./subcomponents/NumberCompare";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import NumberCapital from "./subcomponents/NumberCapital";

const NumbersPage = () => {
  const [compare, setCompare] = useState(false);
  const [gCapital, setGCapital] = useState();

  return (
    <>
      <NumberCapital setGCapital={setGCapital} />

      <Numbers gCapital={gCapital} />

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={{
            marginTop: "30px",
            marginBottom: "30px",
            backgroundColor: "#126E82",
            "&:hover": {
              backgroundColor: "#0A4C5A",
            },
          }}
          endIcon={compare ? <VisibilityOffIcon /> : <VisibilityIcon />}
          onClick={() => setCompare(!compare)}
        >
          Compare with other Month
        </Button>
      </Box>

      {compare && <NumberCompare gCapital={gCapital} />}
    </>
  );
};

export default NumbersPage;
