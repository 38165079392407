import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

let rowImmutableStore;

const UnpaidOrders = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [unpaidPickupOrders, setUnpaidPickupOrders] = useState([]);

  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "InvoiceID",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      minWidth: 150,
    },
    { field: "DriverName" },
    { field: "RAddress", headerName: "Reciever Address", minWidth: 160 },
    { field: "Address", minWidth: 130 },
    { field: "Price" },
    { field: "PriceLBP" },
    { field: "LBPSubPrice", minWidth: 140 },
    { field: "Company" },
    { field: "Date" },
    {
      field: "Paid",
      headerName: "Paid ✏️",
      editable: true,
      minWidth: 140,
      // cellClassRules: {
      //   "highlight-editable": (params) => params.column.getColDef().editable,
      // },
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["yes", "no"],
      },
    },
    { field: "Exchange" },
    { field: "ShipmentStatus", minWidth: 160 },
    { field: "CanPay" },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    minWidth: 130,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));

  const [totalItemPrice, setTotalItemPrice] = useState("");
  // console.log(totalItemPrice);
  // const onGridReady = useCallback((params) => {
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getUnpaidPickupOrders",
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        const transformedData =
          response.data.resultsSelectUnpaidPickupOrders.map((item) => ({
            id: item.id,
            InvoiceID: item.invoiceID,
            Price: item.itemPrice,
            PriceLBP: item.itemPriceLBP,
            LBPSubPrice: item.lbpSubPrice,
            Date: dayjs(item.pickupdate).format("YYYY-MM-DD"),
            Company: item.companyName,
            Paid: item.paid === "1" ? "yes" : "no",
            Exchange: item.exchange,
            ShipmentStatus: item.shipmentStatus,
            RAddress: item.receiverAddress,
            Address: item.address,
            DriverName: `${item.firstname} ${item.lastname}`,
            CanPay: item.shipmentStatus === "delivered" ? "yes" : "no",
          }));

        const totalPrice = transformedData.reduce((total, row) => {
          const ItemPrice = parseFloat(row.Price || 0);
          const LBPSubPrice = parseFloat(row.LBPSubPrice || 0);
          // console.log(total)
          return total + ItemPrice + LBPSubPrice;
        }, 0);
        setTotalItemPrice(totalPrice);

        rowImmutableStore = transformedData;
        setUnpaidPickupOrders(rowImmutableStore);
        document.getElementById("loaderRU").style.visibility = "hidden";
      })
      .catch((error) => {
        setUnpaidPickupOrders([]);
      });
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);

  const onCellEditRequest = useCallback(
    (event) => {
      const data = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const oldItem = rowImmutableStore.find((row) => row.id === data.id);
      if (!oldItem || !field) {
        return;
      }
      const user = Cookies.get("username");
      // console.log(oldItem);
      const newItem = { ...oldItem };
      newItem[field] = newValue;
      console.log("onCellEditRequest, updating " + field + " to " + newValue);
      if (field === "Paid") {
        let data = new FormData();
        data.append("invoiceID", oldItem.InvoiceID);
        data.append("paid", newValue);
        data.append("oldPaid", oldItem.Paid);
        data.append("user", user);

        let config = {
          method: "patch",
          url: "/api/updatePaidInvoice",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            if (response.data.success) {
              alert("Invoice Payment Updated Successfully");
              window.location.reload();
            } else {
              alert("Failed to update invoice payment");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      rowImmutableStore = rowImmutableStore.map((oldItem) =>
        oldItem.id === newItem.id ? newItem : oldItem
      );
      setUnpaidPickupOrders(rowImmutableStore);
    },
    [rowImmutableStore]
  );

  const [selectedRows, setSelectedRows] = useState([]);
  // console.log(selectedRows)
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const [selectedTotal, setSelectedTotal] = useState("");
  const [selectedCount, setSelectedCount] = useState("");

  useEffect(() => {
    let total = 0;
    selectedRows.forEach((row) => {
      total += row.Price + row.LBPSubPrice;
    });

    setSelectedTotal(total);
    setSelectedCount(selectedRows.length);
  }, [selectedRows]);

  return (
    <>
      <Box
        sx={{
          height: 600,
          width: "100%",
          // marginTop: !isBigScreen && "90px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
            gap: "20px",
          }}
        >
          <h2 style={{ color: "#fff" }}>All UnPaid Picked Up Orders</h2>
          <CircularProgress
            id="loaderRU"
            sx={{ color: "#fff", visibility: "hidden" }}
          />

          <button style={{ height: "35px" }} onClick={() => autoSizeAll(false)}>
            Auto-Size All
          </button>
          {unpaidPickupOrders[0] && (
            <>
              <h3 style={{ color: "#fff" }}>
                Order Count: {unpaidPickupOrders.length}
              </h3>
              <h3 style={{ color: "#fff" }}>
                Total Item Price: {totalItemPrice} $
              </h3>
              {selectedRows.length > 0 && (
                <>
                  <h3 style={{ color: "red" }}>
                    Selected Count: {selectedCount}
                  </h3>
                  <h3 style={{ color: "red" }}>
                    Selected Total Items Price: {selectedTotal} $
                  </h3>
                </>
              )}
            </>
          )}
        </div>
        <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
          <AgGridReact
            ref={gridRef}
            rowData={unpaidPickupOrders}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
            getRowId={getRowId}
            onSelectionChanged={onSelectionChanged}
            readOnlyEdit={true}
            // onGridReady={onGridReady}
            onCellEditRequest={onCellEditRequest}
          />
        </div>
      </Box>
    </>
  );
};

export default UnpaidOrders;
