import React from "react";
import { Select, MenuItem } from "@mui/material";

const CustomDriverSelect = ({ drivers, value, onChange, multi }) => {
//   console.log(drivers);
  return (
    <Select
      labelId="select-shipper"
      id="select-shipper"
      value={value}
      label="shipper-name"
      onChange={onChange}
      disabled={multi ? true : false}
      sx={{
        height: multi && "30px",
      }}
    >
      {drivers.map((driver) => (
        <MenuItem key={driver.userID} value={driver.userID}>
          {`${driver.firstname} ${driver.lastname}`}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomDriverSelect;
