import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
// import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

let rowImmutableStore;

const DriverView = () => {
  const username = Cookies.get("username");
  // console.log(username)
  //   const navigate = useNavigate();

  //   useEffect(() => {
  //     if (Cookies.get("role") !== "driver") {
  //       navigate("/login");
  //     }
  //   }, []);

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [invoicesInfo, setInvoicesInfo] = useState([]);

  const now = new Date();
  const today =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
  now.setDate(now.getDate() - 30);
  const week =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  const [fromDate, setFromDate] = useState(week);
  const [toDate, setToDate] = useState(today);

  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [columnDefs, setColumnDefs] = useState([
    { field: "InvoiceID", minWidth: 160 },
    { field: "TotalPrice", minWidth: 160 },
    { field: "Exchange", minWidth: 140 },
    { field: "Address", minWidth: 300 },
    { field: "ReciverAddress", minWidth: 300 },
    { field: "ReciverTel", minWidth: 160 },
    { field: "ReciverName", minWidth: 160 },
    // { field: "Status", minWidth: 160 },
    { field: "Date", minWidth: 160 },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));

  const [totalPrice, setTotalPrice] = useState("0");

  useEffect(() => {
    let data = new FormData();
    data.append("fromDate", fromDate);
    data.append("toDate", toDate);
    data.append("username", username);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/getInvoicesByUsername",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        if (response.data.message === "No Data Found") {
          alert(response.data.message);
        } else {
          const transformedData = response.data.resultsSelectInvoices.map(
            (item) => ({
              InvoiceID: item.invoiceID,
              Address: item.address,
              ReciverAddress: item.receiverAddress,
              ReciverTel: item.receiverTel,
              ReciverName: item.receiverName,
              Exchange: item.exchange,
              TotalPrice: item.totalPrice,
              Status: item.shipmentStatus,
              Date: dayjs(item.statusDate).format("YYYY-MM-DD HH:mm:ss"),
            })
          );

          const totalPrice = transformedData.reduce((total, row) => {
            const ItemPrice = parseFloat(row.TotalPrice || 0);
            // console.log("deliveryProfit for row:", deliveryProfit);
            return total + ItemPrice;
          }, 0);
          setTotalPrice(totalPrice);

          setInvoicesInfo(transformedData);
        }
      })
      .catch((error) => {
        setInvoicesInfo([]);
        // showNoRows();
      });
  }, [fromDate, toDate]);

  //   const showNoRows = useCallback(() => {
  //     gridRef.current.api.showNoRowsOverlay();
  //   }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          flexDirection: !isBigScreen ? "column-reverse" : "row",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              defaultValue={dayjs(week)}
              onChange={(newDate) =>
                setFromDate(dayjs(newDate).format("YYYY-MM-DD"))
              }
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              defaultValue={dayjs(today)}
              onChange={(newDate) =>
                setToDate(dayjs(newDate).format("YYYY-MM-DD"))
              }
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <Box
        sx={{
          height: 600,
          width: "100%",
          // marginTop: "90px",
        }}
      >
        <h3>
          Pending Invoices from {fromDate} to {toDate}
        </h3>

        <button onClick={() => autoSizeAll(false)}>Auto-Size All</button>
        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={invoicesInfo} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            readOnlyEdit={true}
            // overlayNoRowsTemplate={
            //   '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Data Found during this duration</span>'
            // }
          />
        </div>
      </Box>

      <h2 style={{ textAlign: "center" }}>Total : {totalPrice} $</h2>
    </>
  );
};

export default DriverView;
