import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
// import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";
// import dayjs from "dayjs";
import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import CircularProgress from "@mui/material/CircularProgress";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

// let rowImmutableStore;

const PickupReturnsTotal = ({ fromDate, toDate }) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [totalReturn, setTotalReturn] = useState([]);

  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [columnDefs, setColumnDefs] = useState([
    { field: "DriverName", minWidth: 160 },
    { field: "TotalReturns", minWidth: 160 },
    // { field: "Currency" },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));

  useEffect(() => {
    document.getElementById("loaderRT").style.visibility = "visible";
    let data = new FormData();
    data.append("fromDate", fromDate);
    data.append("toDate", toDate);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/getTotalReturnInfo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const transformedData = response.data.resultsSumSubtraction.map(
          (item) => ({
            id: item.userID,
            DriverName: `${item.firstname} ${item.lastname}`,
            TotalReturns: item.total_subtraction,
            // Currency: item.currency,
          })
        );
        setTotalReturn(transformedData);
        document.getElementById("loaderRT").style.visibility = "hidden";
      })
      .catch((error) => {
        setTotalReturn([]);
        document.getElementById("loaderRT").style.visibility = "hidden";
      });
  }, [fromDate, toDate]);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  return (
    <>
      <Box
        sx={{
          height: 600,
          width: isBigScreen ? "40%" : "100%",
          // marginTop: "90px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            marginTop: "10px",
            gap: "20px",
          }}
        >
          <h2 style={{ color: "#fff" }}>Total Return per Driver </h2>
          <CircularProgress
            id="loaderRT"
            sx={{ color: "#fff", visibility: "hidden" }}
          />
        </div>
        <button onClick={() => autoSizeAll(false)}>Auto-Size All</button>
        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={totalReturn} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            readOnlyEdit={true}
          />
        </div>
      </Box>
    </>
  );
};

export default PickupReturnsTotal;
