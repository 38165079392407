import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

let rowImmutableStore;

const DriversAccount = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "InvoiceID",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { field: "Date", minWidth: 180 },
    { field: "Address", minWidth: 150 },
    { field: "ReceiverAddress", minWidth: 250 },
    { field: "Page" },
    { field: "Exchange" },
    {
      field: "ItemPrice",
      headerName: "ItemPrice ✏️",
      editable: true,
      minWidth: 170,
    },
    {
      field: "ItemPriceLBP",
      headerName: "ItemPriceLBP ✏️",
      editable: true,
      minWidth: 170,
    },
    {
      field: "LBPSubPrice",
      headerName: "LBPSubPrice ✏️",
      editable: true,
      minWidth: 170,
    },
    { field: "DeliveryCost" },
    {
      field: "DeliveryProfit",
      headerName: "Delivery Profit ✏️",
      editable: true,
      minWidth: 170,
    },
    { field: "LabytakProfit" },
    { field: "Notes" },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  }));

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const lineBox = {
    display: "flex",
    // flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 0",
  };

  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("0");

  const [selectedStatus, setSelectedStatus] = useState("1");

  useEffect(() => {
    // localStorage.setItem("selectedDriver", "0");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getDrivers",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        response.data.resultsGetDrivers.shift();
        setDrivers(response.data.resultsGetDrivers);
        if (localStorage.getItem("selectedDriver") !== null) {
          setSelectedDriver(localStorage.getItem("selectedDriver"));
        } else {
          setSelectedDriver("0");
        }

        if (localStorage.getItem("selectedStatus") !== null) {
          setSelectedStatus(localStorage.getItem("selectedStatus"));
        } else {
          setSelectedStatus("1");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [driverAccount, setDriverAccount] = useState([]);
  const [orderCount, setOrderCount] = useState("");
  const [lbProfit, setLBProfit] = useState("");
  const [deliveryCost, setDeliveryCost] = useState("");
  const [deliveryProfit, setDeliveryProfit] = useState("");
  const [totalItemPrice, setTotalItemPrice] = useState("");
  const [totalItemPriceLBP, setTotalItemPriceLBP] = useState("");
  const [totalAll, setTotalAll] = useState("");

  useEffect(() => {
    document.getElementById("loaderDA").style.visibility = "visible";
    if (selectedDriver !== "0") {
      let status =
        selectedStatus === "0"
          ? "pickup"
          : selectedStatus === "1"
          ? "pending"
          : selectedStatus === "2"
          ? "delivered"
          : selectedStatus === "3"
          ? "canceled"
          : selectedStatus === "4"
          ? "delayed"
          : selectedStatus === "5"
          ? "cancel_paid"
          : selectedStatus === "6"
          ? "cancel_paid_delivery"
          : selectedStatus === "7"
          ? "pending2"
          : "";
      let data = new FormData();
      data.append("shipperID", selectedDriver);
      data.append("shippmentStatus", status);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/getInvoiceByDriver",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then(async (response) => {
          // console.log(response.data);
          if (response.data.message === "No Data Found") {
            alert("No Data Found");
            setOrderCount("");
            setDeliveryProfit("");
            setTotalItemPrice("");
            setDriverAccount([]);
            document.getElementById("loaderDA").style.visibility = "hidden";
          } else {
            setOrderCount(response.data.resultInvoice.length);
            const transformedData = response.data.resultInvoice.map((row) => ({
              id: row.id,
              InvoiceID: row.invoiceID,
              Date: dayjs(row.statusDate).format("YYYY-MM-DD HH:mm:ss"),
              Address: row.address,
              ReceiverAddress: row.receiverAddress,
              FullName: row.firstname + " " + row.lastname,
              Page: row.companyName,
              Exchange: row.exchange,
              ItemPrice: row.itemPrice,
              ItemPriceLBP: row.itemPriceLBP,
              LBPSubPrice: row.lbpSubPrice,
              DeliveryCost: row.shippingCost,
              DeliveryProfit: row.shipperProfit,
              LabytakProfit: row.labaytakProfit,
              Notes: row.notes,
            }));

            const totalPrice = transformedData.reduce((total, row) => {
              const ItemPrice = parseFloat(row.ItemPrice || 0);
              const LBPSubPrice = parseFloat(row.LBPSubPrice || 0);
              return total + ItemPrice + LBPSubPrice;
            }, 0);
            setTotalItemPrice(totalPrice);

            const totalPriceLBP = transformedData.reduce((total, row) => {
              const LBPPrice = parseFloat(row.ItemPriceLBP || 0);
              return total + LBPPrice;
            }, 0);
            setTotalItemPriceLBP(totalPriceLBP);

            const totalDeliveryCost = transformedData.reduce((total, row) => {
              const deliveryCost = parseFloat(row.DeliveryCost || 0);
              // console.log(deliveryCost)
              return total + deliveryCost;
            }, 0);
            setDeliveryCost(totalDeliveryCost);

            const totalAllItems = transformedData.reduce((total, row) => {
              const ItemPrice = parseFloat(row.ItemPrice || 0);
              const LBPSubPrice = parseFloat(row.LBPSubPrice || 0);
              const deliveryCost = parseFloat(row.DeliveryCost || 0);

              return total + ItemPrice + LBPSubPrice + deliveryCost;
            }, 0);
            setTotalAll(totalAllItems);

            const totalDeliveryProfit = transformedData.reduce((total, row) => {
              const deliveryProfit = parseFloat(row.DeliveryProfit || 0);
              // console.log(deliveryProfit)
              return total + deliveryProfit;
            }, 0);
            setDeliveryProfit(totalDeliveryProfit);

            const totalLabaytakProfit = transformedData.reduce((total, row) => {
              const labaytakProfit = parseFloat(row.LabytakProfit || 0);
              // console.log(labaytakProfit)
              return total + labaytakProfit;
            }, 0);
            setLBProfit(totalLabaytakProfit);

            rowImmutableStore = transformedData;
            setDriverAccount(transformedData);
            document.getElementById("loaderDA").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          setOrderCount("");
          setDeliveryProfit("");
          setTotalItemPrice("");
          setDriverAccount([]);
          document.getElementById("loaderDA").style.visibility = "hidden";
        });
    } else {
      document.getElementById("loaderDA").style.visibility = "hidden";
      return;
    }
  }, [selectedDriver, selectedStatus]);

  const [selectedTotal, setSelectedTotal] = useState("");
  const [selectedTotalLBP, setSelectedTotalLBP] = useState("");
  const [selectedTotalAll, setSelectedTotalAll] = useState("");
  const [selectedTotalDCost, setSelectedTotalDCost] = useState("");
  const [selectedTotalDProfit, setSelectedTotalDProfit] = useState("");
  const [selectedTotalLProfit, setSelectedTotalLProfit] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  // console.log(selectedRows);
  const onSelectionChanged = (event) => {
    // console.log(event.api.getSelectedRows());
    setSelectedRows(event.api.getSelectedRows());
  };

  useEffect(() => {
    let total = 0;
    selectedRows.forEach((row) => {
      total += row.ItemPrice + row.LBPSubPrice;
    });

    let totalLBP = 0;
    selectedRows.forEach((row) => {
      totalLBP += row.ItemPriceLBP;
    });

    let totalDCost = 0;
    selectedRows.forEach((row) => {
      totalDCost += row.DeliveryCost;
    });

    let totalAll = 0;
    selectedRows.forEach((row) => {
      totalAll += row.ItemPrice + row.LBPSubPrice + row.DeliveryCost;
    });

    let totalDProfit = 0;
    selectedRows.forEach((row) => {
      totalDProfit += row.DeliveryProfit;
    });

    let totalLProfit = 0;
    selectedRows.forEach((row) => {
      totalLProfit += row.LabytakProfit;
    });

    setSelectedTotal(total);
    setSelectedTotalLBP(totalLBP);
    setSelectedTotalDCost(totalDCost);
    setSelectedTotalAll(totalAll);
    setSelectedTotalDProfit(totalDProfit);
    setSelectedTotalLProfit(totalLProfit);
  }, [selectedRows]);

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const [multiStatus, setMultiStatus] = useState("1");
  // console.log(multiStatus);
  const handleChangeStatus = () => {
    document.getElementById("loaderCS").style.visibility = "visible";
    let user = Cookies.get("username");
    let status =
      multiStatus === "0"
        ? "pickup"
        : multiStatus === "1"
        ? "pending"
        : multiStatus === "2"
        ? "delivered"
        : multiStatus === "3"
        ? "canceled"
        : multiStatus === "4"
        ? "delayed"
        : multiStatus === "5"
        ? "cancel_paid"
        : multiStatus === "6"
        ? "cancel_paid_delivery"
        : multiStatus === "7"
        ? "pending2"
        : "";
    let data = new FormData();
    data.append("invoices", JSON.stringify(selectedRows));
    data.append("shippmentStatus", status);
    data.append("user", user);

    let config = {
      method: "patch",
      url: "/api/multiUpdateShippmentStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success) {
          alert(response.data.message);
          document.getElementById("loaderCS").style.visibility = "hidden";
          window.location.reload();
        } else {
          alert("Failed to update Status");
          document.getElementById("loaderCS").style.visibility = "hidden";
        }
      })
      .catch((error) => {
        alert("Failed to update Shipper");
        document.getElementById("loaderCS").style.visibility = "hidden";
      });
  };

  const handleDeleteInvoice = () => {
    if (window.confirm("Are you sure you want to delete Invoices!")) {
      document.getElementById("loaderDL").style.visibility = "visible";
      let user = Cookies.get("username");

      let data = new FormData();
      data.append("invoices_IDs", JSON.stringify(selectedRows));
      data.append("userr", user);

      let config = {
        method: "post",
        url: "/api/multiDeleteInvoice",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.success) {
            alert(response.data.message);
            document.getElementById("loaderDL").style.visibility = "hidden";
            window.location.reload();
          } else {
            console.log(response.data);
            alert("Failed to delete page");
            document.getElementById("loaderDL").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to delete page");
          console.log(error);
          document.getElementById("loaderDL").style.visibility = "hidden";
        });
    }
  };

  const [pendingChanges, setPendingChanges] = useState([]);
  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);
  const onCellEditRequest = useCallback(
    (event) => {
      const data = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const oldItem = rowImmutableStore.find((row) => row.id === data.id);
      if (!oldItem || !field) {
        return;
      }
      // console.log(oldItem.UserName);
      const user = Cookies.get("username");
      const newItem = { ...oldItem };
      newItem[field] = newValue;
      // console.log("onCellEditRequest, updating " + field + " to " + newValue);
      if (field === "DeliveryProfit") {
        const change = {
          field: "DeliveryProfit",
          invoice_ID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.DeliveryProfit,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "LBPSubPrice") {
        const change = {
          field: "LBPSubPrice",
          invoice_ID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.LBPSubPrice,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "ItemPrice") {
        const change = {
          field: "ItemPrice",
          invoice_ID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.ItemPrice,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "ItemPriceLBP") {
        const change = {
          field: "ItemPriceLBP",
          invoice_ID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.ItemPriceLBP,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      }

      rowImmutableStore = rowImmutableStore.map((oldItem) =>
        oldItem.id === newItem.id ? newItem : oldItem
      );
      setDriverAccount(rowImmutableStore);
    },
    [rowImmutableStore]
  );

  const saveChanges = () => {
    document.getElementById("loaderS").style.visibility = "visible";
    const successfulChanges = [];
    const failedChanges = [];

    pendingChanges.forEach((change) => {
      const { field, invoice_ID, user, newValue, oldValue } = change;

      let apiUrl;
      let requestData = new FormData();

      if (field === "DeliveryProfit") {
        apiUrl = "/api/updateShipperProfit";
        requestData.append("invoice_ID", invoice_ID);
        requestData.append("cost", newValue);
        requestData.append("oldCost", oldValue);
        requestData.append("user", user);

      } else if (field === "LBPSubPrice") {
        apiUrl = "/api/updateLBPSubPrice";
        requestData.append("invoice_ID", invoice_ID);
        requestData.append("price", newValue);
        requestData.append("oldLBPSubPrice", oldValue);
        requestData.append("user", user);

      } else if (field === "ItemPrice") {
        apiUrl = "/api/updateItemPrice";
        requestData.append("invoice_ID", invoice_ID);
        requestData.append("price", newValue);
        requestData.append("oldItemPrice", oldValue);
        requestData.append("user", user);

      } else if (field === "ItemPriceLBP") {
        apiUrl = "/api/updateItemPriceLBP";
        requestData.append("invoice_ID", invoice_ID);
        requestData.append("price", newValue);
        requestData.append("oldItemPrice", oldValue);
        requestData.append("user", user);

      }

      axios
        .request({
          method: "patch",
          url: apiUrl,
          headers: {
            "Content-Type": "application/json",
          },
          data: requestData,
        })
        .then((response) => {
          if (response.data.success) {
            // console.log(`${field} for ${username} has been updated!`);
            successfulChanges.push(change);
          } else {
            // console.log(`Failed to update ${field} for ${username}`);
            failedChanges.push(change);
          }

          const totalChanges = successfulChanges.length + failedChanges.length;
          if (totalChanges === pendingChanges.length) {
            if (successfulChanges.length === pendingChanges.length) {
              alert(`All changes were updated!`);
              setPendingChanges([]);
              document.getElementById("loaderS").style.visibility = "hidden";
              window.location.reload();
            } else {
              alert(`Failed to edit ${failedChanges.length} records`);
              setPendingChanges([]);
              document.getElementById("loaderS").style.visibility = "hidden";
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          failedChanges.push(change);
          document.getElementById("loaderS").style.visibility = "hidden";
        });
    });
  };

  return (
    <>
      <Box sx={lineBox}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            width: isBigScreen ? "600px" : "100%",
          }}
        >
          <Box>
            <label style={{ marginRight: "5px" }}>Select Driver</label>

            {drivers ? (
              <Select
                labelId="select-shipper"
                id="select-shipper"
                value={selectedDriver}
                label="shipper-name"
                onChange={(e) => {
                  setSelectedDriver(e.target.value);
                  localStorage.setItem("selectedDriver", e.target.value);
                }}
              >
                <MenuItem value={0}>Choose Driver</MenuItem>
                {drivers.map((driver) => (
                  <MenuItem key={driver.userID} value={driver.userID}>
                    {`${driver.firstname} ${driver.lastname}`}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <p>Loading drivers...</p>
            )}
          </Box>

          <Box>
            <label style={{ marginRight: "5px" }}>Select Status</label>
            <Select
              labelId="statuss"
              id="selected-status"
              value={selectedStatus}
              label="status"
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                localStorage.setItem("selectedStatus", e.target.value);
              }}
              // disabled={selectedRows.length > 0 ? false : true}
            >
              <MenuItem value="1">pending</MenuItem>
              <MenuItem value="7">pending2</MenuItem>
              <MenuItem value="2">delivered</MenuItem>
              <MenuItem value="3">canceled</MenuItem>
              <MenuItem value="4">delayed</MenuItem>
              <MenuItem value="5">cancel_paid</MenuItem>
              <MenuItem value="6">cancel_paid_delivery</MenuItem>
            </Select>
          </Box>
          <CircularProgress
            id="loaderDA"
            color="success"
            sx={{ visibility: "hidden" }}
          />
        </Box>
      </Box>
      <Box>
        <h4>Order Count: {orderCount}</h4>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "20px",
          width: "100%",
          alignItems: "center",
          margin: "5px 0",
        }}
      >
        <button onClick={() => autoSizeAll(false)}>Auto-Size All</button>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <Box>
            <label
              style={{
                width: "20%",
                textAlign: "center",
                marginRight: "5px",
              }}
            >
              Multi Status Change
            </label>
            <Select
              labelId="status"
              id="select-status"
              value={multiStatus}
              label="status"
              onChange={(e) => setMultiStatus(e.target.value)}
              disabled={selectedRows.length > 0 ? false : true}
            >
              <MenuItem value="0">pickup</MenuItem>
              <MenuItem value="1">pending</MenuItem>
              <MenuItem value="7">pending2</MenuItem>
              <MenuItem value="2">delivered</MenuItem>
              <MenuItem value="3">canceled</MenuItem>
              <MenuItem value="4">delayed</MenuItem>
              <MenuItem value="5">cancel_paid</MenuItem>
              <MenuItem value="6">cancel_paid_delivery</MenuItem>
            </Select>
          </Box>
          <button
            onClick={handleChangeStatus}
            disabled={selectedRows.length > 0 ? false : true}
          >
            Change Status
          </button>
          <CircularProgress
            id="loaderCS"
            color="success"
            sx={{ visibility: "hidden" }}
          />

          <button
            onClick={saveChanges}
            disabled={pendingChanges.length > 0 ? false : true}
          >
            Save Changes
          </button>
          <CircularProgress
            id="loaderS"
            color="success"
            sx={{ visibility: "hidden" }}
          />

          <button
            onClick={handleDeleteInvoice}
            disabled={selectedRows.length > 0 ? false : true}
          >
            Delete Invoice
          </button>
          <CircularProgress
            id="loaderDL"
            color="success"
            sx={{ visibility: "hidden" }}
          />
        </Box>
      </Box>

      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
      <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
        <AgGridReact
          ref={gridRef}
          rowData={driverAccount}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          getRowId={getRowId}
          onSelectionChanged={onSelectionChanged}
          onCellClicked={cellClickedListener}
          onCellEditRequest={onCellEditRequest}
          suppressRowClickSelection={true}
          readOnlyEdit={true}
        />
      </div>
      {driverAccount[0] && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "100px",
              justifyContent: "center",
              // width: isBigScreen ? "400px" : "100%",
            }}
          >
            {selectedRows.length > 0 && (
              <>
                <h3 style={{ color: "red" }}>
                  Selected Total Items Price: {selectedTotal} $
                </h3>
                <h3 style={{ color: "red" }}>
                  Selected Total Items Price: {selectedTotalLBP} LBP
                </h3>
                <h3 style={{ color: "red" }}>
                  Selected Total Delivery Cost: {selectedTotalDCost} $
                </h3>
                <h3 style={{ color: "red" }}>
                  Selected Total: {selectedTotalAll} $
                </h3>
                <h3 style={{ color: "red" }}>
                  Selected Total {driverAccount[0].FullName} Profit:{" "}
                  {selectedTotalDProfit} $
                </h3>
                <h3 style={{ color: "red" }}>
                  Selected Total Labaytak Profit: {selectedTotalLProfit} $
                </h3>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "100px",
              justifyContent: "center",
              // width: isBigScreen ? "400px" : "100%",
            }}
          >
            <h3>Total Item Price: {totalItemPrice} $</h3>
            <h3>Total LBP Price: {totalItemPriceLBP} LBP</h3>
            <h3>Total Delivery Cost: {deliveryCost} $</h3>
            <h3>Total: {totalAll} $</h3>
            <h3>
              Total Profit for {driverAccount[0].FullName}: {deliveryProfit} $
            </h3>
            <h3>Total Profit Labaytak: {lbProfit} $</h3>
          </Box>
        </>
      )}
    </>
  );
};

export default DriversAccount;
