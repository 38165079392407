import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useMediaQuery, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

let rowImmutableStore;

const Pages = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const gridRef = useRef(); // Optional - for accessing Grid's API

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "CompanyID",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { field: "CompanyName" },
    { field: "PhoneNumber" },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));

  const [pages, setPages] = useState([]);

  useEffect(() => {
    document.getElementById("loaderP").style.visibility = "visible";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getCompanies",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        const transformedData = response.data.resultsGetCompanies.map(
          (user) => ({
            CompanyID: user.companyID,
            CompanyName: user.companyName,
            PhoneNumber: user.compPhoneNumber,
          })
        );
        transformedData.shift();
        // console.log(transformedData)
        rowImmutableStore = transformedData;
        setPages(rowImmutableStore);
        document.getElementById("loaderP").style.visibility = "hidden";
      })
      .catch((error) => {
        setPages([]);
        // showNoRows();
        document.getElementById("loaderP").style.visibility = "hidden";
      });
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const onCellEditRequest = useCallback(
    (event) => {
      const data = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const oldItem = rowImmutableStore.find((row) => row.id === data.id);
      if (!oldItem || !field) {
        return;
      }
      console.log(oldItem);
      const newItem = { ...oldItem };
      newItem[field] = newValue;
      console.log("onCellEditRequest, updating " + field + " to " + newValue);

      rowImmutableStore = rowImmutableStore.map((oldItem) =>
        oldItem.id === newItem.id ? newItem : oldItem
      );
      setPages(rowImmutableStore);
    },
    [rowImmutableStore]
  );

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows());
  };
  // console.log(selectedRows)
  const handleDeletePage = () => {
    if (window.confirm("Are you sure you want to delete page!")) {
      document.getElementById("loaderDL").style.visibility = "visible";
      let user = Cookies.get("username");

      let data = new FormData();
      data.append("pagesIDs", JSON.stringify(selectedRows));
      data.append("userr", user);

      let config = {
        method: "post",
        url: "/api/multiDeletePage",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.success) {
            alert(response.data.message);
            document.getElementById("loaderDL").style.visibility = "hidden";
            window.location.reload();
          } else {
            alert("Failed to delete page");
            document.getElementById("loaderDL").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to delete page");
          document.getElementById("loaderDL").style.visibility = "hidden";
        });
    }
  };

  const lineBox = {
    display: "flex",
    // flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    margin: "5px 0",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    margin: !isBigScreen && "10px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const createPage = () => {
    document.getElementById("loaderAP").style.visibility = "visible";
    if (phoneNumber === "" || companyName === "") {
      document.getElementById("loaderAP").style.visibility = "hidden";
      return alert("Enter all values");
    } else {
      let data = new FormData();
      data.append("companyName", companyName);
      data.append("phoneNumber", phoneNumber);

      let config = {
        method: "post",
        url: "/api/createPage",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.success) {
            alert("Page Created Successfully");
            document.getElementById("loaderAP").style.visibility = "hidden";
            window.location.reload();
          } else {
            alert(response.data.message);
            document.getElementById("loaderAP").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to update Shipper");
          document.getElementById("loaderAP").style.visibility = "hidden";
        });
    }
  };

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          marginTop: "10px",
          gap: "20px",
        }}
      >
        <h2>All Pages</h2>
        <CircularProgress
          id="loaderP"
          color="success"
          sx={{ visibility: "hidden" }}
        />
      </div>
      {/* Example using Grid's API */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>
              Company Name
            </label>
            <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
              <TextField
                id="companyname"
                onChange={(event) => setCompanyName(event.target.value)}
                label="companyname"
                type="text"
                value={companyName}
              />
            </FormControl>
          </Box>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>
              Phone Number
            </label>
            <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
              <TextField
                id="phonenumber"
                onChange={(event) => setPhoneNumber(event.target.value)}
                label="phonenumber"
                type="text"
                value={phoneNumber}
              />
            </FormControl>
          </Box>

          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                backgroundColor: "#126E82",
                "&:hover": {
                  backgroundColor: "#0A4C5A",
                },
              }}
              endIcon={<AddCircleOutlineIcon />}
              onClick={createPage}
            >
              Create Page
            </Button>
          </Box>
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <CircularProgress
              id="loaderAP"
              color="success"
              sx={{ visibility: "hidden" }}
            />
          </div>
        </Box>
      </Modal>
      <Box sx={lineBox}>
        <button onClick={() => autoSizeAll(false)}>Auto-Size All</button>
        <Button onClick={handleOpen} sx={{ color: "#268968" }}>
          Add Page
        </Button>

        <button
          onClick={handleDeletePage}
          disabled={selectedRows.length > 0 ? false : true}
        >
          Delete Page
        </button>
        <CircularProgress
          id="loaderDL"
          color="success"
          sx={{ visibility: "hidden" }}
        />
      </Box>
      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
      <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
        <AgGridReact
          ref={gridRef} // Ref for accessing Grid's API
          rowData={pages} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple" // Options - allows click selection of rows
          onSelectionChanged={onSelectionChanged}
          onCellClicked={cellClickedListener} // Optional - registering for Grid Event
          suppressRowClickSelection={true}
          readOnlyEdit={true}
          // onGridReady={onGridReady}
          // overlayNoRowsTemplate={
          //   '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Data Found during this duration</span>'
          // }
          onCellEditRequest={onCellEditRequest}
        />
      </div>
    </>
  );
};

export default Pages;
