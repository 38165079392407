import React from 'react'
import { Select, MenuItem } from "@mui/material";

const CustomCompanySelector = ({ comapanies, value, onChange }) => {
  return (
    <Select
      labelId="select-shipper"
      id="select-shipper"
      value={value}
      label="shipper-name"
      onChange={onChange}
      // style={{
      //   height: "30px",
      // }}
    >
      {comapanies.map((company) => (
        <MenuItem key={company.companyID} value={company.companyID}>
          {`${company.companyName}`}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CustomCompanySelector