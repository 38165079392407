import React, { useState, useEffect } from "react";
import DynamicItemBox from "./DynamicItemBox";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import axios from "axios";
import CustomCompanySelector from "./CustomCompanySelector";

const DynamicCompanyBox = ({ setFinalCompanyList, drivers }) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [companies, setCompanies] = useState(
    JSON.parse(localStorage.getItem("companies")) || [
      {
        id: 0,
        selectedCompany: 0,
        myitems: [],
      },
    ]
  );

  useEffect(() => {
    setFinalCompanyList(companies);
    localStorage.setItem("companies", JSON.stringify(companies));
  }, [companies]);

  const addCompany = () => {
    const newCompanyId = companies.length;
    const newCompany = [
      ...companies,
      {
        id: newCompanyId,
        selectedCompany: 0,
        myitems: [],
      },
    ];
    setCompanies(newCompany);
  };

  const removeCompany = (id) => {
    // const updatedCompanies = companies.filter((company) => company.id !== id);
    // setCompanies(updatedCompanies);
    


    const updatedCompanies = companies.filter((company) => company.id !== id);
    // Update the IDs of the remaining items
    const updatedCompaniesWithNewIds = updatedCompanies.map((company, index) => ({
      ...company,
      id: index,
    }));
    setCompanies(updatedCompaniesWithNewIds);
  };

  const handleCompanyChange = (id, newComp) => {
    const updatedCompany = companies.map((company) =>
      company.id === id ? { ...company, selectedCompany: newComp } : company
    );
    setCompanies(updatedCompany);
  };

  const companyBox = {
    width: isBigScreen ? "1250px" : "100%",
    height: "max-content",
    backgroundColor: "#fff",
    borderRadius: 2,
    p: 1,
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const lineBox = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  };

  const deleteList = {
    color: "#fff",
    width: "100%",
    backgroundColor: "#276329",
    borderColor: "#276329",
    "&:hover": {
      backgroundColor: "#268968",
      borderColor: "#268968",
      color: "#fff",
      boxShadow: "none",
    },
  };

  const addCompanyBox = {
    color: "#fff",
    backgroundColor: "#ea6e27",
    borderColor: "#ea6e27",
    "&:hover": {
      backgroundColor: "#276329",
      borderColor: "#276329",
      color: "#fff",
      boxShadow: "none",
    },
  };

  const [allCompanies, setAllCompanies] = useState([]);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getCompanies",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setAllCompanies(response.data.resultsGetCompanies);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isBigScreen ? "row" : "column",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {companies.map((company, index) => (
          <Box key={company.id} sx={companyBox}>
            {/* Page Name Box */}
            <Box sx={lineBox}>
              <label style={{ width: "25%", textAlign: "center" }}>
                Page Name {index + 1}
              </label>

              <FormControl required sx={{ m: 1, width: "75%" }} size="medium">
                <InputLabel id="select-company-label">Page Name</InputLabel>

                {allCompanies ? (
                  <CustomCompanySelector
                    comapanies={allCompanies}
                    value={company.selectedCompany}
                    onChange={(e) =>
                      handleCompanyChange(company.id, e.target.value)
                    }
                  />
                ) : (
                  <p>Loading Companies...</p>
                )}
              </FormControl>
            </Box>

            <DynamicItemBox
              companyID={company.id}
              setCompanies={setCompanies}
              drivers={drivers}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button sx={deleteList} onClick={() => removeCompany(company.id)}>
                Delete List <DeleteForeverOutlinedIcon />
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Button sx={addCompanyBox} onClick={addCompany}>
          Add Company <LibraryAddOutlinedIcon />
        </Button>
      </Box>
    </>
  );
};

export default DynamicCompanyBox;
