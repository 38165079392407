import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import SquareIcon from "@mui/icons-material/Square";
import { Select, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import CustomDriverSelect from "./CustomDriverSelect";
import { useMediaQuery, useTheme } from "@mui/material";
import Cookies from "js-cookie";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "../../styles/rowstyles.css";
import GetInvoiceDetails from "./GetInvoiceDetails";
import { useQuery } from "react-query";

let rowImmutableStore;

const GetAllOrdersGrid = ({
  fromDate,
  toDate,
  driversList,
  pages,
  setOrderCount,
  setLBProfit,
}) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  let role = Cookies.get("role");

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "InvoiceID",
      headerName: "InvoiceID ✏️",
      editable: true,
      minWidth: 170,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { field: "PickupDriver" },
    { field: "Funds" },
    { field: "PickupDate" },
    {
      field: "ItemPrice",
      headerName: "Item Price USD ✏️",
      editable: true,
      // headerName: role === "admin" ? "Item Price USD ✏️" : "Item Price USD",
      // editable: role === "admin" ? true : false,
      minWidth: 170,
    },
    {
      field: "ItemPriceLBP",
      headerName: "Item Price LBP ✏️",
      editable: true,
      minWidth: 170,
    },
    {
      field: "LBPSubPrice",
      headerName: "LBP Sub Price ✏️",
      editable: true,
      minWidth: 160,
    },
    {
      field: "PageName",
      headerName: "PageName ✏️",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [],
      },
    },
    {
      field: "Paid",
      headerName: "Paid ✏️",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["yes", "no"],
      },
    },
    { field: "Exchange" },
    {
      field: "Shipper",
      headerName: "Shipper ✏️",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [],
      },
    },
    {
      field: "ShipmentStatus",
      headerName: "ShipmentStatus ✏️",
      minWidth: 170,
      editable: true,
      cellClassRules: {
        "status-pickup": (params) => params.value === "pickup",
        "status-pending": (params) =>
          params.value === "pending" || params.value === "pending2",
        "status-delivered": (params) => params.value === "delivered",
        "status-canceled": (params) => params.value === "canceled",
        "status-delayed": (params) => params.value === "delayed",
        "status-cancel-paid": (params) => params.value === "cancel_paid",
        "status-cancel-paid-delivery": (params) =>
          params.value === "cancel_paid_delivery",
      },
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "pickup",
          "pending",
          "pending2",
          "delivered",
          "canceled",
          "delayed",
          "cancel_paid",
          "cancel_paid_delivery",
        ],
      },
    },
    { field: "Address" },
    {
      field: "ReceiverAddress",
      headerName: "ReceiverAddress ✏️",
      minWidth: 180,
      editable: true,
    },
    { field: "StatusDate", minWidth: 170 },
    {
      field: "ShippingCost",
      headerName: "ShippingCost ✏️",
      minWidth: 160,
      editable: true,
    },
    { field: "TotalPrice" },
    {
      field: "ShipperProfit",
      headerName: "ShipperProfit ✏️",
      minWidth: 160,
      editable: true,
    },
    { field: "LabaytakProfit" },
    {
      field: "Notes",
      headerName: "Notes ✏️",
      minWidth: 180,
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 100,
        rows: 10,
        cols: 50,
      },
    },
    { field: "ReceiverName" },
    { field: "ReceiverTel" },
  ]);

  // Function to update cellEditorParams values
  const updateCellEditorParams = (newValues) => {
    setColumnDefs((prevDefs) => [
      ...prevDefs.map((def) =>
        def.field === "Shipper"
          ? {
              ...def,
              cellEditorParams: { ...def.cellEditorParams, values: newValues },
            }
          : def
      ),
    ]);
  };

  const updateCellEditorParamsPage = (newValues) => {
    setColumnDefs((prevDefs) => [
      ...prevDefs.map((def) =>
        def.field === "PageName"
          ? {
              ...def,
              cellEditorParams: { ...def.cellEditorParams, values: newValues },
            }
          : def
      ),
    ]);
  };

  useEffect(() => {
    updateCellEditorParams(driversList.map((driver) => driver.trim()));
  }, [driversList]);

  useEffect(() => {
    updateCellEditorParamsPage(pages.map((page) => page.companyName));
  }, [pages]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  }));

  useEffect(() => {
    document.getElementById("loader").style.visibility = "visible";
    let data = new FormData();
    data.append("fromDate", fromDate);
    data.append("toDate", toDate);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/getAllOrders",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        // console.log(response.data);
        setOrderCount(response.data.resultsSelectAllOrders.length);
        const transformedData = response.data.resultsSelectAllOrders.map(
          (item) => ({
            id: item.id,
            InvoiceID: item.invoiceID,
            Funds: item.funds,
            PickupDriver: `${item.pickup_driver_firstname} ${item.pickup_driver_lastname}`,
            PickupDate: dayjs(item.pickupdate).format("YYYY-MM-DD"),
            ItemPrice: item.itemPrice,
            ItemPriceLBP: item.itemPriceLBP,
            LBPSubPrice: item.lbpSubPrice,
            Currency: item.currency,
            Paid: item.paid === "1" ? "yes" : "no",
            PageName: item.companyName,
            Shipper: `${item.shipper_firstname} ${item.shipper_lastname}`,
            ShipmentStatus: item.shipmentStatus,
            StatusDate: dayjs(item.statusDate).format("YYYY-MM-DD HH:mm:ss"),
            ShippingCost: item.shippingCost,
            TotalPrice: item.totalPrice,
            ShipperProfit: item.shipperProfit,
            LabaytakProfit: item.labaytakProfit,
            Notes: item.notes,
            Exchange: item.exchange,
            ReceiverName: item.receiverName,
            ReceiverTel: item.receiverTel,
            ReceiverAddress: item.receiverAddress,
            Address: item.address,
          })
        );
        // Calculate total LabaytakProfit
        const totalLabaytakProfit = transformedData.reduce(
          (total, item) => total + item.LabaytakProfit,
          0
        );
        setLBProfit(totalLabaytakProfit);
        rowImmutableStore = transformedData;
        setRowData(transformedData);
        document.getElementById("loader").style.visibility = "hidden";
      })
      .catch((error) => {
        setOrderCount("0");
        setLBProfit("0");
        setRowData([]);
        document.getElementById("loader").style.visibility = "hidden";
      });
  }, [fromDate, toDate]);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);

  const [pendingChanges, setPendingChanges] = useState([]);
  async function checkInvoice(invoice) {
    let data = new FormData();
    data.append("invoiceID", invoice);

    let config = {
      method: "post",
      url: "/api/getInvoiceById",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching invoice:", error.response.data);
      return false;
    }
  }
  // console.log(pendingChanges);
  const onCellEditRequest = useCallback(
    async (event) => {
      document.getElementById("loader").style.visibility = "visible";
      const data = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const oldItem = rowImmutableStore.find((row) => row.id === data.id);
      if (!oldItem || !field) {
        return;
      }
      // console.log(oldItem.PageName);
      const user = Cookies.get("username");
      // console.log(user)
      const newItem = { ...oldItem };
      newItem[field] = newValue;
      console.log("onCellEditRequest, updating " + field + " to " + newValue);

      if (field === "ShipmentStatus") {
        const change = {
          field: "ShipmentStatus",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.ShipmentStatus,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "InvoiceID") {
        try {
          const result = await checkInvoice(newValue);
          if (result === true) {
            console.log("Invoice exists.");
            alert("Invoice already exists: " + newValue);
            document.getElementById("loader").style.visibility = "hidden";
            return result;
          } else {
            const change = {
              field: "InvoiceID",
              rowID: oldItem.id,
              newValue,
              oldValue: oldItem.InvoiceID,
              user: user,
            };

            setPendingChanges((prevChanges) => [...prevChanges, change]);
          }
        } catch (error) {
          console.error("Error checking invoice:", error);
          // Handle the error appropriately
        }
      } else if (field === "Paid") {
        const change = {
          field: "Paid",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.Paid,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "Shipper") {
        const change = {
          field: "Shipper",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.Shipper,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "ReceiverAddress") {
        const change = {
          field: "ReceiverAddress",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.ReceiverAddress,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "LBPSubPrice") {
        const change = {
          field: "LBPSubPrice",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.LBPSubPrice,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "ItemPrice") {
        const change = {
          field: "ItemPrice",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.ItemPrice,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "ItemPriceLBP") {
        const change = {
          field: "ItemPriceLBP",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.ItemPriceLBP,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "Notes") {
        const change = {
          field: "Notes",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.Notes,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "ShippingCost") {
        const change = {
          field: "ShippingCost",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.ShippingCost,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "ShipperProfit") {
        const change = {
          field: "ShipperProfit",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.ShipperProfit,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "PageName") {
        const change = {
          field: "PageName",
          invoiceID: oldItem.InvoiceID,
          newValue,
          oldValue: oldItem.PageName,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      }

      rowImmutableStore = rowImmutableStore.map((oldItem) =>
        oldItem.id === newItem.id ? newItem : oldItem
      );
      setRowData(rowImmutableStore);
      document.getElementById("loader").style.visibility = "hidden";
    },
    [rowImmutableStore]
  );

  const saveChanges = () => {
    document.getElementById("loaderS").style.visibility = "visible";
    const successfulChanges = [];
    const failedChanges = [];

    pendingChanges.forEach((change) => {
      const { field, invoiceID, rowID, user, newValue, oldValue } = change;
      // console.log(change);
      let apiUrl;
      let requestData = new FormData();

      if (field === "ShipmentStatus") {
        apiUrl = "/api/updateShippmentStatus";
        requestData.append("invoiceID", invoiceID);
        requestData.append("shippmentStatus", newValue);
        requestData.append("oldShipmentStatus", oldValue);
        requestData.append("user", user);
        //   data.append("invoiceID", oldItem.InvoiceID);
        //   data.append("shippmentStatus", newValue);
        //   data.append("oldShipmentStatus", oldItem.ShipmentStatus);
        //   data.append("user", user);
      } else if (field === "InvoiceID") {
        // console.log(rowID)
        apiUrl = "/api/updateInvoiceID";
        requestData.append("rowID", rowID);
        requestData.append("newInvoiceID", newValue);
        requestData.append("oldInvoiceID", oldValue);
        requestData.append("user", user);

        //   data.append("rowID", oldItem.id);
        //   data.append("newInvoiceID", newValue);
        //   data.append("oldInvoiceID", oldItem.InvoiceID);
        //   data.append("user", user);
      } else if (field === "Paid") {
        apiUrl = "/api/updatePaidInvoice";
        requestData.append("invoiceID", invoiceID);
        requestData.append("paid", newValue);
        requestData.append("oldPaid", oldValue);
        requestData.append("user", user);

        //   data.append("invoiceID", oldItem.InvoiceID);
        //   data.append("paid", newValue);
        //   data.append("oldPaid", oldItem.Paid);
        //   data.append("user", user);
      } else if (field === "Shipper") {
        apiUrl = "/api/updateShipper";
        requestData.append("invoiceID", invoiceID);
        requestData.append("fullname", newValue);
        requestData.append("oldShipper", oldValue);
        requestData.append("user", user);

        //   data.append("invoiceID", oldItem.InvoiceID);
        //   data.append("fullname", newValue);
        //   data.append("oldShipper", oldItem.Shipper);
        //   data.append("user", user);
      } else if (field === "ReceiverAddress") {
        apiUrl = "/api/updateReceiverAddress";
        requestData.append("invoiceID", invoiceID);
        requestData.append("receiverAddress", newValue);
        requestData.append("oldReceiverAddress", oldValue);
        requestData.append("user", user);

        //   data.append("invoiceID", oldItem.InvoiceID);
        //   data.append("receiverAddress", newValue);
        //   data.append("oldReceiverAddress", oldItem.ReceiverAddress);
        //   data.append("user", user);
      } else if (field === "LBPSubPrice") {
        apiUrl = "/api/updateLBPSubPrice";
        requestData.append("invoice_ID", invoiceID);
        requestData.append("price", newValue);
        requestData.append("oldLBPSubPrice", oldValue);
        requestData.append("user", user);

        //   data.append("invoice_ID", oldItem.InvoiceID);
        //   data.append("price", newValue);
        //   data.append("oldLBPSubPrice", oldItem.LBPSubPrice);
        //   data.append("user", user);
      } else if (field === "ItemPrice") {
        apiUrl = "/api/updateItemPrice";
        requestData.append("invoice_ID", invoiceID);
        requestData.append("price", newValue);
        requestData.append("oldItemPrice", oldValue);
        requestData.append("user", user);

        //   data.append("invoice_ID", oldItem.InvoiceID);
        //   data.append("price", newValue);
        //   data.append("oldItemPrice", oldItem.ItemPrice);
        //   data.append("user", user);
      } else if (field === "ItemPriceLBP") {
        apiUrl = "/api/updateItemPriceLBP";
        requestData.append("invoice_ID", invoiceID);
        requestData.append("price", newValue);
        requestData.append("oldItemPrice", oldValue);
        requestData.append("user", user);

        //   data.append("invoice_ID", oldItem.InvoiceID);
        //   data.append("price", newValue);
        //   data.append("oldItemPrice", oldItem.ItemPrice);
        //   data.append("user", user);
      } else if (field === "Notes") {
        apiUrl = "/api/updateItemNotes";
        requestData.append("invoice_ID", invoiceID);
        requestData.append("notes", newValue);
        requestData.append("oldNotes", oldValue);
        requestData.append("user", user);

        //   data.append("invoice_ID", oldItem.InvoiceID);
        //   data.append("notes", newValue);
        //   data.append("oldNotes", oldItem.Notes);
        //   data.append("user", user);
      } else if (field === "ShippingCost") {
        apiUrl = "/api/updateShippingCost";
        requestData.append("invoice_ID", invoiceID);
        requestData.append("cost", newValue);
        requestData.append("oldCost", oldValue);
        requestData.append("user", user);

        //   data.append("invoice_ID", oldItem.InvoiceID);
        //   data.append("cost", newValue);
        //   data.append("oldCost", oldItem.ShippingCost);
        //   data.append("user", user);
        //   url: "/api/updateShippingCost",
      } else if (field === "ShipperProfit") {
        apiUrl = "/api/updateShipperProfit";
        requestData.append("invoice_ID", invoiceID);
        requestData.append("cost", newValue);
        requestData.append("oldCost", oldValue);
        requestData.append("user", user);

        //   data.append("invoice_ID", oldItem.InvoiceID);
        //   data.append("cost", newValue);
        //   data.append("oldCost", oldItem.ShipperProfit);
        //   data.append("user", user);
        //   url: "/api/updateShipperProfit",
      } else if (field === "PageName") {
        apiUrl = "/api/updatePage";
        requestData.append("invoiceID", invoiceID);
        requestData.append("page", newValue);
        requestData.append("oldPage", oldValue);
        requestData.append("user", user);

        //   data.append("invoiceID", oldItem.InvoiceID);
        //   data.append("page", newValue);
        //   data.append("oldPage", oldItem.PageName);
        //   data.append("user", user);
        //     url: "/api/updatePage",
      }

      axios
        .request({
          method: "patch",
          url: apiUrl,
          headers: {
            "Content-Type": "application/json",
          },
          data: requestData,
        })
        .then((response) => {
          if (response.data.success) {
            // console.log(`${field} for ${username} has been updated!`);
            successfulChanges.push(change);
          } else {
            // console.log(`Failed to update ${field} for ${username}`);
            failedChanges.push(change);
          }

          const totalChanges = successfulChanges.length + failedChanges.length;
          if (totalChanges === pendingChanges.length) {
            if (successfulChanges.length === pendingChanges.length) {
              alert(`All changes were updated!`);
              setPendingChanges([]);
              document.getElementById("loaderS").style.visibility = "hidden";
              window.location.reload();
            } else {
              console.log(response.data);
              alert(`Failed to edit ${failedChanges.length} records`);
              setPendingChanges([]);
              document.getElementById("loaderS").style.visibility = "hidden";
              // window.location.reload();
            }
          }
        })
        .catch((error) => {
          failedChanges.push(change);
          document.getElementById("loaderS").style.visibility = "hidden";
        });
    });
  };

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    // console.log("cellClicked", event);
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);
  // console.log(selectedRows)
  const onSelectionChanged = (event) => {
    // console.log(event.api.getSelectedRows());
    setSelectedRows(event.api.getSelectedRows());
  };

  // console.log(JSON.stringify(selectedRows));

  const [multiStatus, setMultiStatus] = useState("0");
  // console.log(multiStatus);
  const handleChangeStatus = () => {
    document.getElementById("loaderMS").style.visibility = "visible";
    let user = Cookies.get("username");
    let status =
      multiStatus === "0"
        ? "pickup"
        : multiStatus === "1"
        ? "pending"
        : multiStatus === "2"
        ? "delivered"
        : multiStatus === "3"
        ? "canceled"
        : multiStatus === "4"
        ? "delayed"
        : multiStatus === "5"
        ? "cancel_paid"
        : multiStatus === "6"
        ? "cancel_paid_delivery"
        : multiStatus === "7"
        ? "pending2"
        : "";
    let data = new FormData();
    data.append("invoices", JSON.stringify(selectedRows));
    data.append("shippmentStatus", status);
    data.append("user", user);

    let config = {
      method: "patch",
      url: "/api/multiUpdateShippmentStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success) {
          alert(response.data.message);
          document.getElementById("loaderMS").style.visibility = "hidden";
          window.location.reload();
        } else {
          alert("Failed to update Status");
          document.getElementById("loaderMS").style.visibility = "hidden";
        }
      })
      .catch((error) => {
        alert("Failed to update Shipper");
        document.getElementById("loaderMS").style.visibility = "hidden";
      });
  };

  const handleDeleteInvoice = () => {
    if (window.confirm("Are you sure you want to delete Invoices!")) {
      document.getElementById("loaderDL").style.visibility = "visible";
      let user = Cookies.get("username");

      let data = new FormData();
      data.append("invoices_IDs", JSON.stringify(selectedRows));
      data.append("userr", user);

      let config = {
        method: "post",
        url: "/api/multiDeleteInvoice",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.success) {
            alert(response.data.message);
            document.getElementById("loaderDL").style.visibility = "hidden";
            window.location.reload();
          } else {
            console.log(response.data);
            alert("Failed to delete page");
            document.getElementById("loaderDL").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to delete page");
          console.log(error);
          document.getElementById("loaderDL").style.visibility = "hidden";
        });
    }
  };

  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getDrivers",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setDrivers(response.data.resultsGetDrivers);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [multiShipperChange, setMultiShipperChange] = useState("0");

  const handleChangeDriver = () => {
    document.getElementById("loaderMD").style.visibility = "visible";

    let user = Cookies.get("username");
    let data = new FormData();
    data.append("invoices", JSON.stringify(selectedRows));
    data.append("shipper", multiShipperChange);
    data.append("user", user);
    // console.log(multiShipperChange);
    let config = {
      method: "patch",
      url: "/api/multiUpdateShipper",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success) {
          alert(response.data.message);
          document.getElementById("loaderMD").style.visibility = "hidden";
          window.location.reload();
        } else {
          alert("Failed to update Status");
          document.getElementById("loaderMD").style.visibility = "hidden";
        }
      })
      .catch((error) => {
        alert("Failed to update Shipper");
        document.getElementById("loaderMD").style.visibility = "hidden";
      });
  };

  const lineBox = {
    display: "flex",
    flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    margin: "5px 0",
  };
  // Invoice Modal
  const [open, setOpen] = useState(false);
  const [invoiceDetails, setinvoiceDetails] = useState([]);
  const handleOpen = () => {
    let data = new FormData();
    data.append("invoiceID", selectedRows[0].InvoiceID);

    let config = {
      method: "post",
      url: "/api/getInvoiceById",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success) {
          // alert(response.data.message);
          setinvoiceDetails(response.data.resultInvoice[0]);
          setOpen(true);
          // window.location.reload();
        } else {
          alert("Failed to update Status");
        }
      })
      .catch((error) => {
        alert("Failed to update Shipper");
      });
  };

  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: 500,
    bgcolor: "background.paper",
    border: "1x solid #000",
    boxShadow: 24,
    fontFamily: "Oswald, sans-serif",
    p: 3,
  };

  const squaresize = 16;
  const pickupcolor = "#00ff00";
  const pendingcolor = "#ffff00";
  const deliveredcolor = "#008000";
  const cancelcolor = "#ff0000";
  const delayedcolor = "#ffa500";
  const cancelpaidcolor = "#32beac";
  const cancelpaiddeliverycolor = "#df47ec";
  return (
    <>
      <Box
        sx={{
          margin: "10px",
          padding: "0px 10px 10px 10px",
          backgroundColor: "#268968",
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            marginTop: "10px",
            gap: "20px",
            width: "100%",
          }}
        >
          <h2 style={{ color: "#fff" }}>All Orders</h2>
          <CircularProgress
            id="loader"
            sx={{ color: "#fff", visibility: "hidden" }}
          />

          <button
            onClick={saveChanges}
            disabled={pendingChanges.length > 0 ? false : true}
          >
            Save Changes
          </button>
          <CircularProgress
            id="loaderS"
            color="success"
            sx={{ visibility: "hidden" }}
          />

          <ul className="index-list">
            <li>
              <SquareIcon
                sx={{
                  color: pickupcolor,
                  fontSize: squaresize,
                }}
              />
              pickup
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: pendingcolor,
                  fontSize: squaresize,
                }}
              />
              pending
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: deliveredcolor,
                  fontSize: squaresize,
                }}
              />{" "}
              delivered
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: cancelcolor,
                  fontSize: squaresize,
                }}
              />
              canceled
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: delayedcolor,
                  fontSize: squaresize,
                }}
              />
              delayed
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: cancelpaidcolor,
                  fontSize: squaresize,
                }}
              />
              cancel_paid
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: cancelpaiddeliverycolor,
                  fontSize: squaresize,
                }}
              />
              cancel_paid_delivery
            </li>
          </ul>
        </div>
        {/* Example using Grid's API */}
        {/* <button onClick={buttonListener}>Push Me</button> */}
        <Box sx={lineBox}>
          <button
            id="resize"
            onClick={() => autoSizeAll(false)}
            style={{
              height: "30px",
            }}
          >
            Auto-Size All
          </button>
          <Divider orientation="vertical" flexItem />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
              width: isBigScreen ? "420px" : "100%",
            }}
          >
            <Box>
              <label
                style={{
                  width: "20%",
                  textAlign: "center",
                  marginRight: "5px",
                }}
              >
                Multi Status Change
              </label>
              <Select
                labelId="status"
                id="select-status"
                value={multiStatus}
                label="status"
                onChange={(e) => setMultiStatus(e.target.value)}
                disabled={selectedRows.length > 1 ? false : true}
                style={{
                  height: "30px",
                }}
              >
                <MenuItem value="0">pickup</MenuItem>
                <MenuItem value="1">pending</MenuItem>
                <MenuItem value="7">pending2</MenuItem>
                <MenuItem value="2">delivered</MenuItem>
                <MenuItem value="3">canceled</MenuItem>
                <MenuItem value="4">delayed</MenuItem>
                <MenuItem value="5">cancel_paid</MenuItem>
                <MenuItem value="6">cancel_paid_delivery</MenuItem>
              </Select>
            </Box>
            <button
              onClick={handleChangeStatus}
              disabled={selectedRows.length > 1 ? false : true}
              style={{
                height: "30px",
              }}
            >
              Change Status
            </button>

            <CircularProgress
              id="loaderMS"
              sx={{ color: "#fff", visibility: "hidden" }}
            />
          </Box>
          <Divider orientation="vertical" flexItem />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              width: isBigScreen ? "420px" : "100%",
            }}
          >
            <Box>
              <label
                style={{
                  width: "20%",
                  textAlign: "center",
                  marginRight: "5px",
                }}
              >
                Multi Driver Change
              </label>
              {drivers ? (
                <CustomDriverSelect
                  drivers={drivers}
                  value={multiShipperChange}
                  onChange={(e) => setMultiShipperChange(e.target.value)}
                  multi={selectedRows.length > 1 ? false : true}
                />
              ) : (
                <p>Loading drivers...</p>
              )}
            </Box>
            <button
              onClick={handleChangeDriver}
              disabled={selectedRows.length > 1 ? false : true}
              style={{
                height: "30px",
              }}
            >
              Change Driver
            </button>
            <CircularProgress
              id="loaderMD"
              sx={{ color: "#fff", visibility: "hidden" }}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            <button
              onClick={handleOpen}
              disabled={selectedRows.length === 1 ? false : true}
              style={{
                height: "30px",
              }}
            >
              Show Invoice Details
            </button>
          </Box>
          <Divider orientation="vertical" flexItem />
          <button
            onClick={handleDeleteInvoice}
            disabled={selectedRows.length > 0 ? false : true}
          >
            Delete Invoice
          </button>
          <CircularProgress
            id="loaderDL"
            color="success"
            sx={{ visibility: "hidden" }}
          />
          {/* <Box sx={{position: 'relative'}}>
            <ul className="index-list">
              <li>
                <SquareIcon
                  sx={{
                    color: pickupcolor,
                    fontSize: squaresize,
                  }}
                />
                pickup
              </li>
              <li>
                <SquareIcon
                  sx={{
                    color: pendingcolor,
                    fontSize: squaresize,
                  }}
                />
                pending
              </li>
              <li>
                <SquareIcon
                  sx={{
                    color: deliveredcolor,
                    fontSize: squaresize,
                  }}
                />{" "}
                delivered
              </li>
              <li>
                <SquareIcon
                  sx={{
                    color: cancelcolor,
                    fontSize: squaresize,
                  }}
                />
                canceled
              </li>
              <li>
                <SquareIcon
                  sx={{
                    color: delayedcolor,
                    fontSize: squaresize,
                  }}
                />
                delayed
              </li>
              <li>
                <SquareIcon
                  sx={{
                    color: cancelpaidcolor,
                    fontSize: squaresize,
                  }}
                />
                cancel_paid
              </li>
            </ul>
          </Box> */}
          {/* <Button onClick={handleOpen}>Open modal</Button> */}
        </Box>

        <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
            getRowId={getRowId}
            onSelectionChanged={onSelectionChanged}
            onCellClicked={cellClickedListener}
            suppressRowClickSelection={true}
            readOnlyEdit={true}
            onCellEditRequest={onCellEditRequest}
            // pagination={true}
            // paginationAutoPageSize={true}
          />

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                {invoiceDetails ? (
                  <GetInvoiceDetails invoiceDetails={invoiceDetails} />
                ) : (
                  <h1>Loading...</h1>
                )}
              </Box>
            </Fade>
          </Modal>
        </div>
      </Box>
    </>
  );
};

export default GetAllOrdersGrid;
