import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

let rowImmutableStore;

const EditHistory = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState([
    { field: "User" },
    { field: "InvoiceID", maxWidth: "300" },
    { field: "Changed" },
    { field: "From" },
    { field: "To" },
    { field: "Date" },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const [changes, setChanges] = useState([]);
  // console.log(changes)
  useEffect(() => {
    document.getElementById("loaderEH").style.visibility = "visible";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getEditHistory",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.resultsEditHistory)
        const transformedData = response.data.resultsEditHistory.map((row) => ({
          User: row.user,
          InvoiceID: row.invoiceID,
          Changed: row.infoChanged,
          From: row.changedFrom,
          To: row.changedTo,
          Date: dayjs(row.changeDate).format("YYYY-MM-DD HH:mm:ss"),
        }));
        rowImmutableStore = transformedData;
        setChanges(rowImmutableStore);
        document.getElementById("loaderEH").style.visibility = "hidden";
      })
      .catch((error) => {
        setChanges([]);
        document.getElementById("loaderEH").style.visibility = "hidden";
      });
  }, []);

  const lineBox = {
    display: "flex",
    // flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    margin: "5px 0",
  };

  return (
    <>
      <Box sx={lineBox}>
        <button onClick={() => autoSizeAll(false)}>Auto-Size All</button>
        <h1>Edit History</h1>
        <CircularProgress
          id="loaderEH"
          color="success"
          sx={{ visibility: "hidden" }}
        />
      </Box>

      <div className="ag-theme-alpine" style={{ width: "100%", height: 700 }}>
        <AgGridReact
          ref={gridRef}
          rowData={changes}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          readOnlyEdit={true}
        />
      </div>
    </>
  );
};

export default EditHistory;
