import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

let rowImmutableStore;

// const disable = (id, disabled) => {
//   document.querySelector(id).disabled = disabled;
// };

// const setValue = (id, value) => {
//   document.querySelector(id).value = value;
// };

const PickupReturns = ({ fromDate, toDate, driversList }) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [pickupInfo, setPickupInfo] = useState([]);
  // console.log(pickupInfo)
  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "DriverName",
      headerName: "Driver Name ✏️",
      minWidth: 160,
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [],
      },
    },
    {
      field: "Funds",
      headerName: "AmountGiven ✏️",
      editable: true,
      minWidth: 160,
    },
    { field: "Invoices" },
    { field: "InvoiceCount" },
    { field: "Date" },
    { field: "TotalPaid", headerName: "Total Paid USD" },
    { field: "ShouldReturn" },
    {
      field: "ConfirmedReturn",
      headerName: "ConfirmedReturn ✏️",
      editable: true,
      minWidth: 180,
    },
    { field: "NotReturned" },
  ]);

  // Function to update cellEditorParams values
  const updateCellEditorParams = (newValues) => {
    setColumnDefs((prevDefs) => [
      ...prevDefs.map((def) =>
        def.field === "DriverName"
          ? {
              ...def,
              cellEditorParams: { ...def.cellEditorParams, values: newValues },
            }
          : def
      ),
    ]);
  };

  useEffect(() => {
    updateCellEditorParams(driversList.map((driver) => driver.trim()));
  }, [driversList]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    minWidth: 150,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));

  useEffect(() => {
    document.getElementById("loaderR").style.visibility = "visible";
    let data = new FormData();
    data.append("fromDate", fromDate);
    data.append("toDate", toDate);
    // console.log(data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/getPickupInfo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const transformedData = response.data.resultsSelectPickupInfo.map(
          (item) => ({
            id: item.invoice_ids,
            Invoices: item.invoice_ids,
            InvoiceCount: item.item_count,
            driverID: item.driverID,
            Funds: item.funds,
            Date: dayjs(item.pickupdate).format("YYYY-MM-DD"),
            DriverName: `${item.firstname} ${item.lastname}`,
            TotalPaid: item.total_costs,
            ShouldReturn: item.shouldReturn,
            ConfirmedReturn: item.returnedFunds,
            NotReturned: item.notReturned,
          })
        );
        rowImmutableStore = transformedData;
        setPickupInfo(rowImmutableStore);
        document.getElementById("loaderR").style.visibility = "hidden";
      })
      .catch((error) => {
        setPickupInfo([]);
        // showNoRows();
        document.getElementById("loaderR").style.visibility = "hidden";
      });
  }, [fromDate, toDate]);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  // const onFirstDataRendered = useCallback(() => {
  //   setValue("#undoInput", 0);
  //   disable("#undoInput", true);
  //   disable("#undoBtn", true);
  //   setValue("#redoInput", 0);
  //   disable("#redoInput", true);
  //   disable("#redoBtn", true);
  // }, []);

  // const onCellValueChanged = useCallback((params) => {
  //   console.log("cellValueChanged", params);
  //   var undoSize = gridRef.current.api.getCurrentUndoSize();
  //   setValue("#undoInput", undoSize);
  //   disable("#undoBtn", undoSize < 1);
  //   var redoSize = gridRef.current.api.getCurrentRedoSize();
  //   setValue("#redoInput", redoSize);
  //   disable("#redoBtn", redoSize < 1);
  // }, []);

  // const onUndoStarted = useCallback((event) => {
  //   console.log("undoStarted", event);
  // }, []);

  // const onUndoEnded = useCallback((event) => {
  //   console.log("undoEnded", event);
  // }, []);

  // const onRedoStarted = useCallback((event) => {
  //   console.log("redoStarted", event);
  // }, []);

  // const onRedoEnded = useCallback((event) => {
  //   console.log("redoEnded", event);
  // }, []);

  // const undo = useCallback(() => {
  //   gridRef.current.api.undoCellEditing();
  // }, []);

  // const redo = useCallback(() => {
  //   gridRef.current.api.redoCellEditing();
  // }, []);

  const [pendingChanges, setPendingChanges] = useState([]);

  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);

  const onCellEditRequest = useCallback(
    (event) => {
      const data = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const oldItem = rowImmutableStore.find((row) => row.id === data.id);
      if (!oldItem || !field) {
        return;
      }
      const user = Cookies.get("username");
      const newItem = { ...oldItem };
      newItem[field] = newValue;

      if (field === "ConfirmedReturn") {
        const change = {
          field: "ConfirmedReturn",
          invoices_IDs: oldItem.Invoices,
          newValue,
          oldValue: oldItem.ConfirmedReturn,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "Funds") {
        const change = {
          field: "Funds",
          invoices_IDs: oldItem.id,
          newValue,
          oldValue: oldItem.AmountGiven,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      } else if (field === "DriverName") {
        const change = {
          field: "DriverName",
          invoices_IDs: oldItem.id,
          newValue,
          oldValue: oldItem.DriverName,
          user: user,
        };

        setPendingChanges((prevChanges) => [...prevChanges, change]);
      }

      rowImmutableStore = rowImmutableStore.map((oldItem) =>
        oldItem.id === newItem.id ? newItem : oldItem
      );
      setPickupInfo(rowImmutableStore);
    },
    [rowImmutableStore]
  );

  const saveChanges = () => {
    document.getElementById("loaderS").style.visibility = "visible";
    const successfulChanges = [];
    const failedChanges = [];

    pendingChanges.forEach((change) => {
      const { field, invoices_IDs, user, newValue, oldValue } = change;

      let apiUrl;
      let requestData = new FormData();

      if (field === "ConfirmedReturn") {
        apiUrl = "/api/updateAmountReturned";
        requestData.append("invoices_IDs", invoices_IDs);
        requestData.append("amount", newValue);
        requestData.append("oldAmount", oldValue);
        requestData.append("user", user);

        //   data.append("invoices_IDs", oldItem.Invoices);
        //   data.append("amount", newValue);
        //   data.append("oldAmount", oldItem.ConfirmedReturn);
        //   data.append("user", user);
      } else if (field === "Funds") {
        apiUrl = "/api/updateAmountGiven";
        requestData.append("invoices_IDs", invoices_IDs);
        requestData.append("amount", newValue);
        requestData.append("oldAmount", oldValue);
        requestData.append("user", user);

        //   data.append("invoices_IDs", oldItem.id);
        //   data.append("amount", newValue);
        //   data.append("oldAmount", oldItem.AmountGiven);
        //   data.append("user", user);
      } else if (field === "DriverName") {
        apiUrl = "/api/updatePickupDriver";
        requestData.append("invoices_IDs", invoices_IDs);
        requestData.append("fullname", newValue);
        requestData.append("oldPickup", oldValue);
        requestData.append("user", user);

        //   data.append("invoices_IDs", oldItem.id);
        //   data.append("fullname", newValue);
        //   data.append("oldPickup", oldItem.DriverName);
        //   data.append("user", user);
      }

      axios
        .request({
          method: "patch",
          url: apiUrl,
          headers: {
            "Content-Type": "application/json",
          },
          data: requestData,
        })
        .then((response) => {
          if (response.data.success) {
            // console.log(`${field} for ${username} has been updated!`);
            successfulChanges.push(change);
          } else {
            // console.log(`Failed to update ${field} for ${username}`);
            failedChanges.push(change);
          }

          const totalChanges = successfulChanges.length + failedChanges.length;
          if (totalChanges === pendingChanges.length) {
            if (successfulChanges.length === pendingChanges.length) {
              alert(`All changes were updated!`);
              setPendingChanges([]);
              document.getElementById("loaderS").style.visibility = "hidden";
              window.location.reload();
            } else {
              alert(`Failed to edit ${failedChanges.length} records`);
              setPendingChanges([]);
              document.getElementById("loaderS").style.visibility = "hidden";
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          failedChanges.push(change);
          document.getElementById("loaderS").style.visibility = "hidden";
        });
    });
  };

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const onSelectionChanged = (event) => {
    console.log(event.api.getSelectedRows());
  };

  return (
    <>
      <Box
        sx={{
          height: 600,
          width: "100%",
          // marginTop: "90px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            marginTop: "10px",
            gap: "20px",
          }}
        >
          <h2 style={{ color: "#fff" }}>Pick Ups</h2>
          <CircularProgress
            id="loaderR"
            sx={{ color: "#fff", visibility: "hidden" }}
          />

          <button style={{ height: "35px" }} onClick={() => autoSizeAll(false)}>
            Auto-Size All
          </button>

          <button
            onClick={saveChanges}
            disabled={pendingChanges.length > 0 ? false : true}
            style={{ height: "35px" }}
          >
            Save Changes
          </button>
          <CircularProgress
            id="loaderS"
            // color="success"
            sx={{ visibility: "hidden", color: "#fff" }}
          />

          {/* <label>Available Undo's</label>
          <input id="undoInput" className="undo-redo-input" />
          <label>Available Redo's</label>
          <input id="redoInput" className="undo-redo-input" />
          <button id="undoBtn" className="undo-btn" onClick={undo}>
            Undo
          </button>
          <button id="redoBtn" className="redo-btn" onClick={redo}>
            Redo
          </button> */}
        </div>

        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div
          id="returns"
          className="ag-theme-alpine"
          style={{ width: "100%", height: 500 }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={pickupInfo}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            undoRedoCellEditing={true}
            animateRows={true}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            getRowId={getRowId}
            readOnlyEdit={true}
            onCellEditRequest={onCellEditRequest}
            // onFirstDataRendered={onFirstDataRendered}
            // onCellValueChanged={onCellValueChanged}
            // onUndoStarted={onUndoStarted}
            // onUndoEnded={onUndoEnded}
            // onRedoStarted={onRedoStarted}
            // onRedoEnded={onRedoEnded}
          />
        </div>
      </Box>
    </>
  );
};

export default PickupReturns;
