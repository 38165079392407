import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";

// axios.defaults.baseURL = "http://localhost:3001";
// axios.defaults.baseURL = "http://91.215.186.112:5555";
axios.defaults.baseURL = "https://api.la-baytak.com";
// axios.defaults.baseURL = "http://api.dailybeautycare.net";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <App />
      </BrowserRouter>
  </React.StrictMode>
);
