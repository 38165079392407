import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useMediaQuery, useTheme } from "@mui/material";
import DynamicCompanyBox from "./subcomponents/DynamicCompanyBox";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import axios from "axios";

// localStorage.setItem("cacheFlag", 0);

const CreateOrders = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [driver, setDriver] = useState("");
  const [funds, setFunds] = useState("");

  const now = new Date();
  const today =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  const [pickupDate, setPickupDate] = useState(today);
  const [finalCompanyList, setFinalCompanyList] = useState([]);

  async function checkInvoice(invoice) {
    let data = new FormData();
    data.append("invoiceID", invoice);

    let config = {
      method: "post",
      url: "/api/getInvoiceById",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success) {
          return true;
        }
      })
      .catch((error) => {
        return false;
      });
  }

  async function checkEmptyValues(obj) {
    if (!obj) return true;

    if (obj.driver === "" || obj.funds === "") {
      alert("Do not leave Pickup Name or Fund Amount empty!");
      return true;
    }

    const seenInvoiceIDs = []; // Maintain an array to store seen invoiceIDs

    for (const company of obj.companylist) {
      if (company.selectedCompany === 0) {
        alert("Choose a Page Name for page " + (company.id + 1));
        return true;
      }
      for (const item of company.myitems) {
        if (item.invoiceID === "") {
          alert(
            "Enter an InvoiceID for item " +
              (item.id + 1) +
              " on page " +
              (company.id + 1)
          );
          return true;
        } else {
          try {
            const result = await checkInvoice(item.invoiceID); // Replace with the invoice ID you want to check
            if (result === true) {
              // Do something when it's true
              console.log("Invoice exists.");
              // alert("Invoice already exist: " + item.invoiceID);
              alert(
                "Invoice already exist: " +
                  item.invoiceID +
                  " on item" +
                  (item.id + 1) +
                  " on page " +
                  (company.id + 1)
              );
              return result;
            } else if (result === false) {
              // Do something when it's false
              console.log("Invoice doesn't exist.");
              // alert("Invoice already exist: " + item.invoiceID);
              // return result;
            }
          } catch (error) {
            // Handle any errors that occurred during the Promise execution.
            console.error(error);
          }
        }

        if (seenInvoiceIDs.includes(item.invoiceID.trim())) {
          alert("Duplicate InvoiceID found: " + item.invoiceID.trim());
          return true;
        }

        seenInvoiceIDs.push(item.invoiceID.trim()); // Add the invoiceID to the seenInvoiceIDs array
        // console.log(seenInvoiceIDs)
        if (item.itemPrice === "") {
          alert(
            "Enter an Item Price for item " +
              (item.id + 1) +
              " on page " +
              (company.id + 1)
          );
          return true;
        }
        if (item.shipper === "") {
          alert(
            "Select Shipper for item " +
              (item.id + 1) +
              " on page " +
              (company.id + 1) +
              " or set as unknown"
          );
          return true;
        }

        if (item.address === "") {
          alert(
            "Select Address for item" +
              (item.id + 1) +
              " on page " +
              (company.id + 1)
          );
          return true;
        }
        // if (item.totalPrice === "") {
        //   alert("Enter an Item Price for item " + (item.id + 1) + " on page " + (company.id + 1));
        //   return true;
        // }
      }
    }

    return false;
  }

  const handleStorageChange = (field, value) => {
    if (field === "driver") {
      const myObject = {
        driver: value,
        funds: funds,
        pickupDate: pickupDate,
      };
      const jsonString = JSON.stringify(myObject);
      localStorage.setItem("pickup", jsonString);
    } else if (field === "funds") {
      const myObject = {
        driver: driver,
        funds: value,
        pickupDate: pickupDate,
      };
      const jsonString = JSON.stringify(myObject);
      localStorage.setItem("pickup", jsonString);
    } else if (field === "pickupDate") {
      const myObject = {
        driver: driver,
        funds: funds,
        pickupDate: value,
      };
      const jsonString = JSON.stringify(myObject);
      localStorage.setItem("pickup", jsonString);
    }
    localStorage.setItem("cacheFlag", 1);
  };

  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    // localStorage.setItem("cacheFlag", 0);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getDrivers",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.resultsGetDrivers);
        setDrivers(response.data.resultsGetDrivers);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (drivers) {
      if (
        localStorage.getItem("cacheFlag") == 1 &&
        localStorage.getItem("pickup")
      ) {
        const storedJsonString = localStorage.getItem("pickup");

        const parsedData = JSON.parse(storedJsonString);

        setDriver(parsedData.driver);
        setFunds(parsedData.funds);
        setPickupDate(parsedData.pickupDate);
      } else {
        const myObject = {
          driver: driver,
          funds: funds,
          pickupDate: pickupDate,
        };
        const jsonString = JSON.stringify(myObject);
        localStorage.setItem("pickup", jsonString);
        // console.log("created Storage");
      }
    }
  }, [drivers]);

  const [visible, setVisible] = useState("hidden");
  const [disabled, setDisabled] = useState(false);

  const finalSubmit = async () => {
    if (window.confirm("Are you sure you want to submit and save orders!")) {
      setDisabled(true);
      setVisible("visible");

      const newOrder = {
        driver: driver,
        funds: funds,
        pickupDate: pickupDate,
        companylist: finalCompanyList,
      };

      try {
        const result = await checkEmptyValues(newOrder);
        if (result === true) {
          console.log("Invoice exists.");

          setDisabled(false);
          setVisible("hidden");
          console.log("in empty value");

          return;
        } else if (result === false) {
          // Do something when it's false
          console.log("Invoice doesn't exist.");

          const config = {
            method: "post",
            url: "/api/createOrder",
            headers: {
              "Content-Type": "application/json",
            },
            data: newOrder,
          };

          axios
            .request(config)
            .then((response) => {
              const { success, data } = response.data;
              if (success) {
                const { successfulInserts, failedInserts, failedInvoiceIDs } =
                  data;
                alert(`Order processing completed:
                       Successful Inserts: ${successfulInserts}
                       Failed Inserts: ${failedInserts}
                       Failed Invoice IDs: ${failedInvoiceIDs.join(", ")}`);
                if (successfulInserts > 0) {
                  localStorage.clear();
                  setDisabled(false);
                  setVisible("hidden");
                  document.location.reload();
                } else {
                  setDisabled(false);
                  setVisible("hidden");
                }
              } else {
                alert("Failed To Create Order");
                setDisabled(false);
                setVisible("hidden");
              }
            })
            .catch((error) => {
              alert("Failed To Create Order");
              setDisabled(false);
              setVisible("hidden");
            });
          // const config = {
          //   method: "post",
          //   url: "/api/createOrder",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   data: newOrder,
          // };
          // axios
          //   .request(config)
          //   .then((response) => {
          //     // console.log(response.data.success);
          //     if (response.data.success === true) {
          //       alert("Order Created Successfully");
          //       localStorage.clear();
          //       setDisabled(false);
          //       setVisible("hidden");
          //       document.location.reload();
          //     } else {
          //       alert("Failed To Create Order");
          //       setDisabled(false);
          //       setVisible("hidden");
          //     }
          //   })
          //   .catch((error) => {
          //     alert("Failed To Create Order");
          //     setDisabled(false);
          //     setVisible("hidden");
          //   });
        }
      } catch (error) {
        // Handle any errors that occurred during the Promise execution.
        console.error(error);
      }
    }
  };

  const DriverMenuItems = () => {
    return (
      <>
        <Select
          labelId="select-driver"
          id="select-driver"
          value={driver}
          label="Pickup-name"
          onChange={(event) => {
            setDriver(event.target.value);
            handleStorageChange("driver", event.target.value);
          }}
        >
          {drivers.map((driver, index) => (
            <MenuItem key={index} value={driver.userID}>
              {`${driver.firstname} ${driver.lastname}`}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  const mediumBox = {
    width: isBigScreen ? "450px" : "100%",
    height: "max-content",
    borderRadius: 2,
    p: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const mediumBox2 = {
    width: isBigScreen ? "450px" : "100%",
    height: "max-content",
    backgroundColor: "#fff",
    borderRadius: 2,
    p: 1,
    marginLeft: "auto",
    marginRight: "auto",
  };

  const lineBox = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  };

  const submitButton = {
    color: "#fff",
    width: "50%",
    backgroundColor: "#276329",
    borderColor: "#276329",
    "&:hover": {
      backgroundColor: "#268968",
      borderColor: "#268968",
      color: "#fff",
      boxShadow: "none",
    },
  };

  return (
    <>
      <Box sx={mediumBox}>
        <Box sx={{ display: "flex", justifyContent: "center" }}></Box>
        <h1 style={{ textAlign: "center", color: "#3a3952" }}>
          New Pickup Order
        </h1>
        <Button sx={submitButton} onClick={finalSubmit} disabled={disabled}>
          Submit <ThumbUpAltOutlinedIcon />
        </Button>
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            marginTop: "10px",
            gap: "20px",
          }}
        >
          {visible == "visible" && (
            <CircularProgress
              id="loaderC"
              color="success"
              // sx={{ visibility: visible }}
            />
          )}
        </div>
      </Box>
      <Box sx={mediumBox2}>
        <Box sx={lineBox}>
          <label style={{ width: "20%", textAlign: "center" }}>
            Pickup Name
          </label>
          <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
            <InputLabel id="select-driver-label">Pickup Name</InputLabel>

            {drivers && <DriverMenuItems />}
          </FormControl>
        </Box>

        <Box sx={lineBox}>
          <label style={{ width: "20%", textAlign: "center" }}>
            Fund Amount
          </label>
          <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
            <TextField
              id="fundsAmount"
              onChange={(event) => {
                setFunds(event.target.value);
                handleStorageChange("funds", event.target.value);
              }}
              label="Funds Amount"
              type="number"
              value={funds}
            />
          </FormControl>
        </Box>

        <Box sx={lineBox}>
          <label style={{ width: "20%", textAlign: "center" }}>
            Pickup Date
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ m: 1, width: "80%" }}
              label="Pickup Date"
              defaultValue={dayjs(today)}
              onChange={(newDate) => {
                setPickupDate(dayjs(newDate).format("YYYY-MM-DD HH:mm:ss"));
                handleStorageChange(
                  "pickupDate",
                  dayjs(newDate).format("YYYY-MM-DD HH:mm:ss")
                );
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <DynamicCompanyBox
        setFinalCompanyList={setFinalCompanyList}
        drivers={drivers}
      />
    </>
  );
};

export default CreateOrders;
