import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useMediaQuery, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

let rowImmutableStore;

const Payments = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [columnDefs, setColumnDefs] = useState([
    { field: "Amount" },
    { field: "PaymentInfo" },
    { field: "Date" },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    minWidth: 160,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const lineBox = {
    display: "flex",
    // flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    margin: !isBigScreen && "10px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const now = new Date();
  const today =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  now.setDate(now.getDate() - 30);
  const month =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  const [fromDate, setFromDate] = useState(month);
  const [toDate, setToDate] = useState(today);

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    document.getElementById("loaderPY").style.visibility = "visible";
    let data = new FormData();
    data.append("fromDate", fromDate);
    data.append("toDate", toDate);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/getPayments",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const transformedData = response.data.resultPayments.map((item) => ({
          id: item.invoice_ids,
          Amount: item.amount,
          PaymentInfo: item.reason,
          Date: dayjs(item.paymentDate).format("YYYY-MM-DD"),
        }));

        rowImmutableStore = transformedData;
        setPayments(rowImmutableStore);
        document.getElementById("loaderPY").style.visibility = "hidden";
      })
      .catch((error) => {
        setPayments([]);
        document.getElementById("loaderPY").style.visibility = "hidden";
      });
  }, [fromDate, toDate]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [newAmount, setNewAmount] = useState("");
  const [paymentInfo, setPaymentInfo] = useState("");

  const createPayment = () => {
    document.getElementById("loaderNPY").style.visibility = "visible";
    if (newAmount === "" || paymentInfo === "") {
      document.getElementById("loaderNPY").style.visibility = "hidden";
      return alert("Enter all values");
    } else {
      let data = new FormData();
      data.append("newAmount", newAmount);
      data.append("paymentInfo", paymentInfo);

      let config = {
        method: "post",
        url: "/api/createPayment",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.success) {
            alert("Payment Created Successfully");
            document.getElementById("loaderNPY").style.visibility = "hidden";
            window.location.reload();
          } else {
            alert(response.data.message);
            document.getElementById("loaderNPY").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to update Shipper");
          document.getElementById("loaderNPY").style.visibility = "hidden";
        });
    }
  };

  const numberInputs = document.querySelectorAll('input[type="number"]');

  numberInputs.forEach((input) => {
    input.addEventListener("wheel", (e) => {
      // Prevent the default behavior of mouse wheel scrolling
      e.preventDefault();
    });
  });

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          marginTop: "10px",
          gap: "20px",
        }}
      >
        <h2>Payments</h2>
        <CircularProgress
          id="loaderPY"
          color="success"
          sx={{ visibility: "hidden" }}
        />
      </div>
      <Box sx={lineBox}>
        <Button onClick={handleOpen} sx={{ color: "#268968" }}>
          New Payment
        </Button>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            defaultValue={dayjs(month)}
            onChange={(newDate) =>
              setFromDate(dayjs(newDate).format("YYYY-MM-DD"))
            }
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            defaultValue={dayjs(today)}
            onChange={(newDate) =>
              setToDate(dayjs(newDate).format("YYYY-MM-DD"))
            }
          />
        </LocalizationProvider>
      </Box>
      <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
        <AgGridReact
          ref={gridRef}
          rowData={payments}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          readOnlyEdit={true}
        />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>Amount</label>
            <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
              <TextField
                id="newAmount"
                label="Amount"
                type="number"
                value={newAmount}
                onChange={(event) => setNewAmount(event.target.value)}
              />
            </FormControl>
          </Box>
          <Box sx={lineBox}>
            <label style={{ width: "20%", textAlign: "center" }}>
              Payment Info
            </label>
            <FormControl required sx={{ m: 1, width: "80%" }} size="medium">
              <TextField
                id="paymentInfo"
                onChange={(event) => setPaymentInfo(event.target.value)}
                label="PaymentInfo"
                type="text"
                multiline
                value={paymentInfo}
              />
            </FormControl>
          </Box>

          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                backgroundColor: "#126E82",
                "&:hover": {
                  backgroundColor: "#0A4C5A",
                },
              }}
              endIcon={<AddCircleOutlineIcon />}
              onClick={createPayment}
            >
              Add Payment
            </Button>
          </Box>
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <CircularProgress
              id="loaderNPY"
              color="success"
              sx={{ visibility: "hidden" }}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Payments;
