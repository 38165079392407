import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useMediaQuery, useTheme } from "@mui/material";
// import PickupFunds from "./subcomponents/PickupFunds";
import PickupReturns from "./subcomponents/PickupReturns";
import PickupReturnsTotal from "./subcomponents/PickupReturnsTotal";
import UnpaidOrders from "./subcomponents/UnpaidOrders";
import GetAllOrdersGrid from "./subcomponents/GetAllOrdersGrid";

import "../styles/OrdersHead.css";
import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";

import axios from "axios";

const AllOrders = () => {
  let role = Cookies.get("role");
  // console.log(role)
  const [drivers, setDrivers] = useState([]);
  const [pages, setPages] = useState([]);
  // console.log(pages);
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getDrivers",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setDrivers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    ////////////////
    let configPages = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getCompanies",
      headers: {},
    };

    axios
      .request(configPages)
      .then((responsePages) => {
        // responsePages.data.resultsGetCompanies.shift();
        setPages(responsePages.data.resultsGetCompanies);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [driversList, setDriversList] = useState([]);
  // console.log(driversList);
  useEffect(() => {
    if (drivers.length !== 0) {
      const fullNameArray = drivers.resultsGetDrivers
        .map((driver) => `${driver.firstname} ${driver.lastname}`)
        .filter((firstname) => firstname.trim() !== "");
      setDriversList(fullNameArray);
    } else {
      const fullNameArray = ["Getting Drivers"];
      setDriversList(fullNameArray);
    }
  }, [drivers]);

  const now = new Date();
  const today =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  now.setDate(now.getDate() - 7);
  const week =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
  // console.log(week);

  now.setDate(now.getDate() - 60);
  const sixtyDaysAgo =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  const [fromDate, setFromDate] = useState(week);
  const [toDate, setToDate] = useState(today);
  // console.log(fromDate)
  // console.log(toDate)

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [orderCount, setOrderCount] = useState("Calculating..");
  const [lbProfit, setLBProfit] = useState("Calculating..");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          flexDirection: !isBigScreen ? "column-reverse" : "row",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              defaultValue={dayjs(week)}
              onChange={(newDate) =>
                setFromDate(dayjs(newDate).format("YYYY-MM-DD"))
              }
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              defaultValue={dayjs(today)}
              onChange={(newDate) =>
                setToDate(dayjs(newDate).format("YYYY-MM-DD"))
              }
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <GetAllOrdersGrid
        fromDate={fromDate}
        toDate={toDate}
        driversList={driversList}
        pages={pages}
        setOrderCount={setOrderCount}
        setLBProfit={setLBProfit}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: isBigScreen ? "row" : "column",
          alignItems: "center",
          gap: "20px",
          margin: "10px",
          padding: "0px 10px 10px 10px",
          backgroundColor: "#268968",
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <PickupReturns
          fromDate={fromDate}
          toDate={toDate}
          driversList={driversList}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isBigScreen ? "row" : "column",
          gap: "20px",
          margin: "10px",
          padding: "10px",
          backgroundColor: "#353535",
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        {/* <PickupFunds fromDate={fromDate} toDate={toDate} /> */}
        <PickupReturnsTotal fromDate={fromDate} toDate={toDate} />

        <UnpaidOrders />
      </Box>
    </>
  );
};

export default AllOrders;
