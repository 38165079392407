import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";

import "../../styles/invoice.css";

const GetInvoiceDetails = ({invoiceDetails}) => {
    // console.log(invoiceDetails)
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const itemLine = {
    display: "flex",
    flexDirection: "row",
    flexWrap: isBigScreen ? "nowrap" : "wrap",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px",
    marginTop: "10px",
  };
  const inputLine = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  };
  const headLine = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  };
  return (
    <>
      <h2 style={{ fontFamily: "Oswald, sans-serif" }}>INVOICE: {invoiceDetails.invoiceID}</h2>

      <Box sx={headLine}>
        <Box className="invoice-address">
          <h3>Receiver: </h3>
          <h5>{invoiceDetails.receiverName}</h5>
          <p className="mt-0 mb-0">PhonNumber: {invoiceDetails.receiverTel}</p>
          <p className="mt-0">Address line: {invoiceDetails.receiverAddress}</p>
        </Box>
        <Box>
          <ul>
            <li>Total Price $: {invoiceDetails.itemPrice}</li>
            <li>Total Price LBP: {invoiceDetails.itemPriceLBP} <small>Sub Price $: {invoiceDetails.lbpSubPrice}</small></li>
            <li>Shipping Cost: {invoiceDetails.shippingCost}</li>
            <li>Total Price: {invoiceDetails.totalPrice}</li>
            <li>Invoice Date: {dayjs(invoiceDetails.pickupdate).format("YYYY-MM-DD")}</li>
            <li>Satus: {invoiceDetails.shipmentStatus}</li>
          </ul>
        </Box>
      </Box>

      <Box sx={itemLine}>
        <Box sx={inputLine}>
          <table className="table table-hover text-right thead-primary">
            <thead>
              <tr className="text-capitalize">
                <th className="text-left">Price ($)</th>
                <th className="text-left">Page</th>
                <th className="text-left">PageContact</th>
                <th className="text-left">Paid</th>
                <th className="text-left">Shipper</th>
                <th className="text-left">Shipper Profit</th>
                <th className="text-left">Labaytak Profit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">{invoiceDetails.itemPrice}</td>
                <td className="text-left">{invoiceDetails.companyName}</td>
                <td className="text-left">{invoiceDetails.compPhoneNumber}</td>
                <td className="text-left">{invoiceDetails.paid=== "1" ? "yes" : "no"}</td>
                <td className="text-left">{invoiceDetails.firstname + invoiceDetails.lastname}</td>
                <td className="text-left">{invoiceDetails.shipperProfit}</td>
                <td className="text-left">{invoiceDetails.labaytakProfit}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
};

export default GetInvoiceDetails;
