import React, { useState, useEffect } from "react";
import "../styles/loginpage.css";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate } from "react-router-dom";

const LoginPage = ({ setLoggedIn, setRole }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [userName, setUserName] = useState("");
  const [pass, setPass] = useState("");

  useEffect(() => {
    if (Cookies.get("loggedIn") === "logged") {
      setRole(Cookies.get("role"));
      setLoggedIn(true);
      navigate("/dashboard/all-orders");
    }
  }, []);

  const loginAction = () => {
    document.getElementById("loader").style.visibility = "visible";

    if (userName === "" || pass === "") {
      document.getElementById("loader").style.visibility = "hidden";
      alert("Enter username and password");
    } else {
      let data = new FormData();
      data.append("username", userName);
      data.append("password", pass);

      let config = {
        method: "post",
        url: "/api/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.success === true) {
            Cookies.set("loggedIn", "logged", { expires: 1 });
            Cookies.set("username", response.data.username, { expires: 1 });
            Cookies.set("role", response.data.role, { expires: 1 });
            // alert(response.data.role);
            setRole(response.data.role);
            setLoggedIn(true);
            if (
              response.data.role === "admin" ||
              response.data.role === "data-entry"
            ) {
              navigate("/dashboard/all-orders");
            } else {
              navigate("/dashboard/driver");
            }
          } else {
            alert(response.data.message);
            return;
          }
        })
        .catch((error) => {
          alert("Invalid Credentials");
          document.getElementById("loader").style.visibility = "hidden";
        });
    }
  };

  return (
    <div className="login">
      <div className="login_container">
        <Avatar
          alt="Labaytak"
          sx={{
            width: 56,
            height: 56,
            marginTop: "40px",
            backgroundColor: "#51C4D3",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <FormControl
            sx={{
              m: 1,
              width: isBigScreen ? "100%" : "85%",
            }}
            variant="standard"
          >
            <InputLabel htmlFor="username">Username</InputLabel>
            <Input
              id="username"
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              endAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            sx={{
              m: 1,
              width: isBigScreen ? "100%" : "85%",
            }}
            variant="standard"
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPass(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Button
              variant="contained"
              sx={{
                marginTop: "50px",
                backgroundColor: "#126E82",
                "&:hover": {
                  backgroundColor: "#0A4C5A",
                },
              }}
              endIcon={<LoginIcon />}
              onClick={loginAction}
            >
              Login
            </Button>
            <div
              id="loader"
              style={{
                display: "inline-flex",
                justifyContent: "center",
                marginTop: "10px",
                visibility: "hidden",
              }}
            >
              <CircularProgress color="success" />
            </div>
          </FormControl>
        </Box>
      </div>
    </div>
  );
};

export default LoginPage;
