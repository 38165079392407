import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

let rowImmutableStore;
const PageAccount = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const gridRef = useRef();

  const now = new Date();
  const today =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  now.setDate(now.getDate() - 2);
  const fromday =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  const [fromDate, setFromDate] = useState(fromday);
  const [toDate, setToDate] = useState(today);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "InvoiceID",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { field: "PickUp" },
    { field: "ItemPrice" },
    { field: "ItemPriceLBP" },
    { field: "LBPSubPrice" },
    { field: "PickUpDate" },
    { field: "Paid" },
    { field: "Exchange" },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  }));

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const lineBox = {
    display: "flex",
    // flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 0",
  };

  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState("0");

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/getPages",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        response.data.resultsGetPages.shift();
        setPages(response.data.resultsGetPages);

        if (localStorage.getItem("selectedPage") !== null) {
          setSelectedPage(localStorage.getItem("selectedPage"));
        } else {
          setSelectedPage("0");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [pageAccount, setPageAccount] = useState([]);
  const [orderCount, setOrderCount] = useState("");
  const [totalItemPrice, setTotalItemPrice] = useState("");

  useEffect(() => {
    document.getElementById("loaderPA").style.visibility = "visible";
    if (selectedPage !== "0") {
      let data = new FormData();
      data.append("pageID", selectedPage);
      data.append("fromDate", fromDate);
      data.append("toDate", toDate);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/getInvoiceByPage",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then(async (response) => {
          // console.log(response.data);
          if (response.data.message === "No Data Found") {
            alert("No Data Found");
            setPageAccount([]);
            document.getElementById("loaderPA").style.visibility = "hidden";
          } else {
            setOrderCount(response.data.resultPageInvoices.length);
            const transformedData = response.data.resultPageInvoices.map(
              (row) => ({
                id: row.id,
                InvoiceID: row.invoiceID,
                PickUp: row.firstname + " " + row.lastname,
                ItemPrice: row.itemPrice,
                ItemPriceLBP: row.itemPriceLBP,
                LBPSubPrice: row.lbpSubPrice,
                PickUpDate: dayjs(row.pickupdate).format("YYYY-MM-DD"),
                Paid: row.paid === "1" ? "yes" : "no",
                Exchange: row.exchange,
              })
            );

            const totalPrice = transformedData.reduce((total, row) => {
              const ItemPrice = parseFloat(row.ItemPrice || 0);
              const LBPSubPrice = parseFloat(row.LBPSubPrice || 0);
              return total + ItemPrice + LBPSubPrice;
            }, 0);
            setTotalItemPrice(totalPrice);

            rowImmutableStore = transformedData;
            setPageAccount(rowImmutableStore);
            document.getElementById("loaderPA").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          document.getElementById("loaderPA").style.visibility = "hidden";
        });
    } else {
      document.getElementById("loaderPA").style.visibility = "hidden";
      return;
    }
  }, [selectedPage, fromDate, toDate]);

  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows());
  };
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const [selectedTotal, setSelectedTotal] = useState("");
  const [selectedCount, setSelectedCount] = useState("");

  useEffect(() => {
    let total = 0;
    selectedRows.forEach((row) => {
      total += row.ItemPrice + row.LBPSubPrice;
    });

    setSelectedTotal(total);
    setSelectedCount(selectedRows.length);
  }, [selectedRows]);


  const handleDeleteInvoice = () => {
    if (window.confirm("Are you sure you want to delete Invoices!")) {
      document.getElementById("loaderDL").style.visibility = "visible";
      let user = Cookies.get("username");

      let data = new FormData();
      data.append("invoices_IDs", JSON.stringify(selectedRows));
      data.append("userr", user);

      let config = {
        method: "post",
        url: "/api/multiDeleteInvoice",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.success) {
            alert(response.data.message);
            document.getElementById("loaderDL").style.visibility = "hidden";
            window.location.reload();
          } else {
            console.log(response.data)
            alert("Failed to delete page");
            document.getElementById("loaderDL").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to delete page");
          console.log(error)
          document.getElementById("loaderDL").style.visibility = "hidden";
        });
    }
  };
  return (
    <>
      <Box sx={lineBox}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            width: isBigScreen ? "400px" : "100%",
          }}
        >
          <Box>
            <label style={{ marginRight: "5px" }}>Select Page</label>

            {pages ? (
              <Select
                labelId="select-page"
                id="select-page"
                value={selectedPage}
                label="page-name"
                onChange={(e) => {
                  setSelectedPage(e.target.value);
                  localStorage.setItem("selectedPage", e.target.value);
                }}
              >
                <MenuItem value={0}>Choose Page</MenuItem>
                {pages.map((page) => (
                  <MenuItem key={page.companyID} value={page.companyID}>
                    {`${page.companyName}`}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <p>Loading Pages...</p>
            )}
          </Box>
          <CircularProgress
            id="loaderPA"
            color="success"
            sx={{ visibility: "hidden" }}
          />
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            defaultValue={dayjs(fromday)}
            onChange={(newDate) =>
              setFromDate(dayjs(newDate).format("YYYY-MM-DD"))
            }
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            defaultValue={dayjs(today)}
            onChange={(newDate) =>
              setToDate(dayjs(newDate).format("YYYY-MM-DD"))
            }
          />
        </LocalizationProvider>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "20px",
          width: "100%",
          alignItems: "center",
          margin: "5px 0",
        }}
      >
        <h4>Order Count: {orderCount}</h4>
        <button onClick={() => autoSizeAll(false)}>Auto-Size All</button>

        {pageAccount[0] && (
          <>
            <h3>Total Item Price: {totalItemPrice} $</h3>
            {selectedRows.length > 0 && (
              <>
                <h3 style={{ color: "red" }}>
                  Selected Count: {selectedCount}
                </h3>
                <h3 style={{ color: "red" }}>
                  Selected Total Items Price: {selectedTotal} $
                </h3>
              </>
            )}
          </>
        )}

        <button
          onClick={handleDeleteInvoice}
          disabled={selectedRows.length > 0 ? false : true}
        >
          Delete Invoice
        </button>
        <CircularProgress
          id="loaderDL"
          color="success"
          sx={{ visibility: "hidden" }}
        />
      </Box>

      <div className="ag-theme-alpine" style={{ width: "100%", height: 650 }}>
        <AgGridReact
          ref={gridRef}
          rowData={pageAccount}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          onSelectionChanged={onSelectionChanged}
          onCellClicked={cellClickedListener}
          suppressRowClickSelection={true}
          readOnlyEdit={true}
        />
      </div>
    </>
  );
};

export default PageAccount;
