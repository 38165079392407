import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import SquareIcon from "@mui/icons-material/Square";
import { Select, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import CustomDriverSelect from "./subcomponents/CustomDriverSelect";
import { useMediaQuery, useTheme } from "@mui/material";
import Cookies from "js-cookie";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "../styles/rowstyles.css";
import GetInvoiceDetails from "./subcomponents/GetInvoiceDetails";
import { useQuery } from "react-query";

let rowImmutableStore;

const ArchivedInvoices = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  let role = Cookies.get("role");

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "InvoiceID",
      minWidth: 170,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { field: "PickupDriver" },
    { field: "Funds" },
    { field: "PickupDate" },
    {
      field: "ItemPrice",
      minWidth: 170,
    },
    { field: "ItemPriceLBP" },
    {
      field: "LBPSubPrice",
      minWidth: 160,
    },
    {
      field: "PageName",
    },
    {
      field: "Paid",
    },
    { field: "Exchange" },
    {
      field: "Shipper",
    },
    {
      field: "ShipmentStatus",
      cellClassRules: {
        "status-pickup": (params) => params.value === "pickup",
        "status-pending": (params) =>
          params.value === "pending" || params.value === "pending2",
        "status-delivered": (params) => params.value === "delivered",
        "status-canceled": (params) => params.value === "canceled",
        "status-delayed": (params) => params.value === "delayed",
        "status-cancel-paid": (params) => params.value === "cancel_paid",
        "status-cancel-paid-delivery": (params) =>
          params.value === "cancel_paid_delivery",
      },
    },
    {
      field: "Address",
      minWidth: 180,
    },
    {
      field: "ReceiverAddress",
      minWidth: 180,
    },
    { field: "StatusDate", minWidth: 170 },
    {
      field: "ShippingCost",
      minWidth: 160,
    },
    { field: "TotalPrice" },
    {
      field: "ShipperProfit",
      minWidth: 160,
    },
    { field: "LabaytakProfit" },
    {
      field: "Notes",
      minWidth: 180,
    },
    { field: "ReceiverName" },
    { field: "ReceiverTel" },
  ]);
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  }));

  useEffect(() => {
    document.getElementById("loader").style.visibility = "visible";

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/getAllArchivedOrders",
      headers: {
        "Content-Type": "application/json",
      },
      // data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        // console.log(response.data);
        //   setOrderCount(response.data.resultsSelectAllOrders.length);
        const transformedData = response.data.resultsSelectAllOrders.map(
          (item) => ({
            id: item.id,
            InvoiceID: item.invoiceID,
            Funds: item.funds,
            PickupDriver: `${item.pickup_driver_firstname} ${item.pickup_driver_lastname}`,
            PickupDate: dayjs(item.pickupdate).format("YYYY-MM-DD"),
            ItemPrice: item.itemPrice,
            ItemPriceLBP: item.itemPriceLBP,
            LBPSubPrice: item.lbpSubPrice,
            Currency: item.currency,
            Paid: item.paid === "1" ? "yes" : "no",
            PageName: item.companyName,
            Shipper: `${item.shipper_firstname} ${item.shipper_lastname}`,
            ShipmentStatus: item.shipmentStatus,
            StatusDate: dayjs(item.statusDate).format("YYYY-MM-DD HH:mm:ss"),
            ShippingCost: item.shippingCost,
            TotalPrice: item.totalPrice,
            ShipperProfit: item.shipperProfit,
            LabaytakProfit: item.labaytakProfit,
            Notes: item.notes,
            Exchange: item.exchange,
            ReceiverName: item.receiverName,
            ReceiverTel: item.receiverTel,
            ReceiverAddress: item.receiverAddress,
            Address: item.address,
          })
        );

        rowImmutableStore = transformedData;
        setRowData(transformedData);
        document.getElementById("loader").style.visibility = "hidden";
      })
      .catch((error) => {
        setRowData([]);
        document.getElementById("loader").style.visibility = "hidden";
      });
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    // console.log("cellClicked", event);
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);
  console.log(selectedRows);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleRestoreInvoice = () => {
    if (window.confirm("Are you sure you want to restore Invoices!")) {
      document.getElementById("loaderDL").style.visibility = "visible";
      let user = Cookies.get("username");

      let data = new FormData();
      data.append("invoices_IDs", JSON.stringify(selectedRows));
      data.append("userr", user);

      let config = {
        method: "post",
        url: "/api/restoreDeleteInvoice",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.success) {
            alert(response.data.message);
            document.getElementById("loaderDL").style.visibility = "hidden";
            window.location.reload();
          } else {
            console.log(response.data);
            alert("Failed to delete page");
            document.getElementById("loaderDL").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          alert("Failed to delete page");
          console.log(error);
          document.getElementById("loaderDL").style.visibility = "hidden";
        });
    }
  };

  const lineBox = {
    display: "flex",
    flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    margin: "5px 0",
  };
  
  const squaresize = 16;
  const pickupcolor = "#00ff00";
  const pendingcolor = "#ffff00";
  const deliveredcolor = "#008000";
  const cancelcolor = "#ff0000";
  const delayedcolor = "#ffa500";
  const cancelpaidcolor = "#32beac";
  const cancelpaiddeliverycolor = "#df47ec";
  return (
    <>
      <Box
        sx={{
          margin: "10px",
          padding: "0px 10px 10px 10px",
          backgroundColor: "#268968",
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            marginTop: "10px",
            gap: "20px",
            width: "100%",
          }}
        >
          <h2 style={{ color: "#fff" }}>All Orders</h2>
          <CircularProgress
            id="loader"
            sx={{ color: "#fff", visibility: "hidden" }}
          />

          <ul className="index-list">
            <li>
              <SquareIcon
                sx={{
                  color: pickupcolor,
                  fontSize: squaresize,
                }}
              />
              pickup
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: pendingcolor,
                  fontSize: squaresize,
                }}
              />
              pending
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: deliveredcolor,
                  fontSize: squaresize,
                }}
              />{" "}
              delivered
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: cancelcolor,
                  fontSize: squaresize,
                }}
              />
              canceled
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: delayedcolor,
                  fontSize: squaresize,
                }}
              />
              delayed
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: cancelpaidcolor,
                  fontSize: squaresize,
                }}
              />
              cancel_paid
            </li>
            <li>
              <SquareIcon
                sx={{
                  color: cancelpaiddeliverycolor,
                  fontSize: squaresize,
                }}
              />
              cancel_paid_delivery
            </li>
          </ul>
        </div>
        {/* Example using Grid's API */}
        <Box sx={lineBox}>
          <button
            id="resize"
            onClick={() => autoSizeAll(false)}
            style={{
              height: "30px",
            }}
          >
            Auto-Size All
          </button>
          <Divider orientation="vertical" flexItem />

          <button
            onClick={handleRestoreInvoice}
            disabled={selectedRows.length > 0 ? false : true}
          >
            Restore Invoices
          </button>
          <CircularProgress
            id="loaderDL"
            color="success"
            sx={{ visibility: "hidden" }}
          />
        </Box>

        <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
            getRowId={getRowId}
            onSelectionChanged={onSelectionChanged}
            onCellClicked={cellClickedListener}
            suppressRowClickSelection={true}
            readOnlyEdit={true}
          />
        </div>
      </Box>
    </>
  );
};

export default ArchivedInvoices;
