import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import LogoutIcon from "@mui/icons-material/Logout";
import StoreIcon from "@mui/icons-material/Store";
import HistoryIcon from "@mui/icons-material/History";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import DescriptionIcon from "@mui/icons-material/Description";
import ArchiveIcon from "@mui/icons-material/Archive";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SearchIcon from "@mui/icons-material/Search";
import { Link, Outlet, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const drawerWidth = 225;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: "#268968",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#268968",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppDrawer = ({ setLoggedIn, setRole }) => {
  let role = Cookies.get("role");
  // console.log(role)
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // for each list item
  const [selectedList, setSelectedList] = useState("Pickup Orders");

  const handleItemClick = (item) => {
    setSelectedList(item);
  };

  const logoutAction = () => {
    Cookies.remove("loggedIn");
    Cookies.remove("role");
    Cookies.remove("username");
    Cookies.remove("loggedIn");
    setLoggedIn(false);
    setRole("");
    navigate("/login");
  };
  const [selectedTab, setSelectedTab] = useState("");
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    // Map the URL path to the corresponding tab value
    let newTab = "";

    if (currentPath === "/dashboard/all-orders") {
      newTab = "all-orders";
    } else if (currentPath === "/dashboard/create-orders") {
      newTab = "create-orders";
    } else if (currentPath === "/dashboard/drivers-account") {
      newTab = "drivers-account";
    } else if (currentPath === "/dashboard/page-account") {
      newTab = "page-account";
    } else if (currentPath === "/dashboard/payments") {
      newTab = "payments";
    } else if (currentPath === "/dashboard/numbers") {
      newTab = "numbers";
    } else if (currentPath === "/dashboard/users") {
      newTab = "users";
    } else if (currentPath === "/dashboard/pages") {
      newTab = "pages";
    } else if (currentPath === "/dashboard/invoice-details") {
      newTab = "invoice-details";
    } else if (currentPath === "/dashboard/edit-history") {
      newTab = "edit-history";
    } else if (currentPath === "/dashboard/archive") {
      newTab = "archive";
    }

    setSelectedTab(newTab);
  }, [location.pathname]);

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab);
  };
  // console.log(selectedList)
  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" sx={{ backgroundColor: "#268968" }} open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Labaytak
          </Typography>
          <LocalShippingOutlinedIcon sx={{ marginLeft: "10px" }} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: "#268968" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List sx={{ color: "#fff" }}>
          {/* SIDE BAR INFO */}
          {role === "admin" || role === "data-entry" ? (
            <>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "all-orders" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/all-orders"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("all-orders")}
                  className={selectedTab === "all-orders" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ProductionQuantityLimitsIcon
                      className={selectedTab === "all-orders" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="All Orders"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "create-orders" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/create-orders"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("create-orders")}
                  className={selectedTab === "create-orders" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AddShoppingCartIcon
                      className={
                        selectedTab === "create-orders" ? "active" : ""
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Create Orders"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "drivers-account" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/drivers-account"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("drivers-account")}
                  className={selectedTab === "drivers-account" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <GroupAddIcon
                      className={
                        selectedTab === "drivers-account" ? "active" : ""
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Drivers Account"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "page-account" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/page-account"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("page-account")}
                  className={selectedTab === "page-account" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <DescriptionIcon
                      className={selectedTab === "page-account" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Page Account"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "invoice-details" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/invoice-details"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("invoice-details")}
                  className={selectedTab === "invoice-details" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <SearchIcon
                      className={
                        selectedTab === "invoice-details" ? "active" : ""
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Find Invoice"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "payments" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/payments"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("payments")}
                  className={selectedTab === "payments" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <HomeRepairServiceIcon
                      className={selectedTab === "payments" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Payments"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "numbers" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/numbers"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("numbers")}
                  className={selectedTab === "numbers" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FormatListNumberedIcon
                      className={selectedTab === "numbers" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Numbers"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "users" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/users"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("users")}
                  className={selectedTab === "users" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <TransferWithinAStationIcon
                      className={selectedTab === "users" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Employees"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "pages" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/pages"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("pages")}
                  className={selectedTab === "pages" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <StoreIcon
                      className={selectedTab === "pages" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Pages"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "edit-history" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/edit-history"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("edit-history")}
                  className={selectedTab === "edit-history" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <HistoryIcon
                      className={selectedTab === "edit-history" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Edit History"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "archive" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/archive"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("archive")}
                  className={selectedTab === "archive" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ArchiveIcon
                      className={selectedTab === "archive" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Archive"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          ) : (
            // : role === "data-entry" ? (
            //   <>
            //     <ListItem disablePadding sx={{ display: "block" }}>
            //       <ListItemButton
            //         button="true"
            //         component={Link}
            //         to="/dashboard/all-orders"
            //         sx={{
            //           minHeight: 48,
            //           justifyContent: open ? "initial" : "center",
            //           px: 2.5,
            //         }}
            //       >
            //         <ListItemIcon
            //           sx={{
            //             minWidth: 0,
            //             mr: open ? 3 : "auto",
            //             justifyContent: "center",
            //           }}
            //         >
            //           <ProductionQuantityLimitsIcon />
            //         </ListItemIcon>
            //         <ListItemText
            //           primary="All Orders"
            //           sx={{ opacity: open ? 1 : 0 }}
            //         />
            //       </ListItemButton>
            //     </ListItem>

            //     <ListItem disablePadding sx={{ display: "block" }}>
            //       <ListItemButton
            //         button="true"
            //         component={Link}
            //         to="/dashboard/create-orders"
            //         sx={{
            //           minHeight: 48,
            //           justifyContent: open ? "initial" : "center",
            //           px: 2.5,
            //         }}
            //       >
            //         <ListItemIcon
            //           sx={{
            //             minWidth: 0,
            //             mr: open ? 3 : "auto",
            //             justifyContent: "center",
            //           }}
            //         >
            //           <AddShoppingCartIcon />
            //         </ListItemIcon>
            //         <ListItemText
            //           primary="Create Orders"
            //           sx={{ opacity: open ? 1 : 0 }}
            //         />
            //       </ListItemButton>
            //     </ListItem>
            //   </>
            // )
            <>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className={selectedTab === "driver" ? "active" : ""}
              >
                <ListItemButton
                  button="true"
                  component={Link}
                  to="/dashboard/driver"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleTabChange("driver")}
                  className={selectedTab === "driver" ? "active" : ""}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <StoreIcon
                      className={selectedTab === "driver" ? "active" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Invoices"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              button="true"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={logoutAction}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Outlet role={role} />
      </Box>
    </Box>
  );
};

export default AppDrawer;
